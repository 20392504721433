import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import {
	TeamRegistrationForm,
	TeamRegistrationFormProps,
} from './TeamRegistrationForm';

import {
	Contest,
	useContestTeamRegistrationDetailsQuery,
	useRegisterTeamMutation,
} from '../../gql/gql';

interface RegisterTeamProps {
	contestId: Contest['id'];
	onRegister: () => void;
}
export function TeamRegistrationComponent({
	contestId,
	onRegister,
}: RegisterTeamProps) {
	const toast = useToast();

	const { data } = useContestTeamRegistrationDetailsQuery({
		variables: { contestId },
	});

	const min = data?.contest?.minTeamSize ?? 0;
	const max = data?.contest?.maxTeamSize ?? 0;

	const [registerTeam] = useRegisterTeamMutation();
	const handleTeamRegistration: TeamRegistrationFormProps['onSubmit'] =
		useCallback(
			async ({ teamName, athletes }) => {
				await registerTeam({
					variables: {
						contestId,
						teamName,
						athleteIds:
							max > 0
								? athletes?.map(({ athleteId }) => athleteId!)
								: undefined,
					},
				});
				toast({
					title: 'Team wurde gemeldet.',
					status: 'success',
				});
				onRegister?.();
			},
			[onRegister, max, contestId, registerTeam, toast]
		);

	return (
		<TeamRegistrationForm
			onSubmit={handleTeamRegistration}
			min={min}
			max={max}
		/>
	);
}
