import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Icon,
	VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';

import { ContestDetailPage } from './Details';
import { ContestsListPage } from './List';

import { useContestNameQuery } from '../../gql/gql';

function ContestBreadcrumb() {
	const { id } = useParams();
	const { data } = useContestNameQuery({
		variables: { id: id ?? '' },
		fetchPolicy: 'cache-only',
	});

	return (
		<BreadcrumbItem isCurrentPage={true}>
			<span>{data?.contest?.name ?? '...'}</span>
		</BreadcrumbItem>
	);
}
export function Contests() {
	const { t } = useTranslation();

	return (
		<VStack align='stretch'>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to='/'>
						<Icon as={FaHome} />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to={'/contests'}>
						{t('page.contests.menu')}
					</BreadcrumbLink>
				</BreadcrumbItem>
				<Routes>
					<Route path=':id' Component={ContestBreadcrumb} />
				</Routes>
			</Breadcrumb>
			<Routes>
				<Route index Component={ContestsListPage} />
				<Route path=':id' Component={ContestDetailPage} />
			</Routes>
		</VStack>
	);
}
