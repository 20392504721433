import { useParams } from 'react-router-dom';

import { ContestDetails } from '../../component/contest/ContestDetails';

export function ContestDetailPage() {
	const { id } = useParams();

	if (id == null) {
		throw new Error('Missing ID');
	}

	return <ContestDetails contestId={id} />;
}
