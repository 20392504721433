import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Athlete, Contest, useRegisterAthleteMutation } from '../../gql/gql';
import { AthleteSelect } from '../athletes/AthleteSelect';

export interface AthleteRegistrationComponentProps {
	contestId: Contest['id'];
	onRegister?: () => void;
}
export function AthleteRegistrationComponent({
	contestId,
	onRegister,
}: AthleteRegistrationComponentProps) {
	const { t } = useTranslation();
	const toast = useToast();
	const [registerAthleteMutation, registrationDetails] =
		useRegisterAthleteMutation();
	const registerAthlete = useCallback(
		async (id: Athlete['id']) => {
			await registerAthleteMutation({
				variables: {
					athleteId: id,
					contestId,
				},
			});
			toast({
				title: t('register.athlete.success'),
				status: 'success',
			});
			onRegister?.();
		},
		[contestId, registerAthleteMutation, onRegister, toast, t]
	);

	const filter = [
		{ athleteRegistrations: { none: { contest: { id: { eq: contestId } } } } },
	];

	return <AthleteSelect onSelect={registerAthlete} filter={filter} />;
	// 		{/* <form onChange={handleSubmit(fetchData)}>
	// 			<Input type='search' {...register('searchText')} />
	// 			<Checkbox {...register('f')} icon={<Icon as={FaFemale} />} />
	// 			<ToggleButton aria-label="männlich" {...getCheckboxProps({ value: Gender.Male })} as={FaMale} />
	// 			<ToggleButton aria-label="weiblich" {...getCheckboxProps({ value: Gender.Female })} as={FaFemale} />

	// 			<RangeSlider value={yearRange} onChange={setYearRange} min={1900} max={2022}>
	// 				<RangeSliderTrack>
	// 					<RangeSliderFilledTrack />
	// 				</RangeSliderTrack>
	// 				<RangeSliderThumb boxSize={10} index={0}>
	// 					{yearRange[0]}
	// 				</RangeSliderThumb>
	// 				<RangeSliderThumb boxSize={10} index={1}>
	// 					{yearRange[1]}
	// 				</RangeSliderThumb>
	// 			</RangeSlider>
	// 		</form> */}

	// );
}
