import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
	ResetPasswordForm,
	ResetPasswordFormValues,
} from './ResetPasswordForm';

import { appolloToFormErrors } from '../../common/error';
import { useUpdatePasswordWithTokenMutation } from '../../gql/gql';

import type { ResetPasswordFormProps } from './ResetPasswordForm';

export function ResetPasswordComponent() {
	const { token } = useParams();
	const { t } = useTranslation();
	const toast = useToast();
	const navigate = useNavigate();

	const [resetPassword, resetPasswordStatus] =
		useUpdatePasswordWithTokenMutation();
	const errors = useMemo(
		() => appolloToFormErrors(resetPasswordStatus.error),
		[resetPasswordStatus.error]
	);

	const handleResetPassword: ResetPasswordFormProps['onSubmit'] = useCallback(
		async (data) => {
			try {
				await resetPassword({
					variables: {
						password: data.password,
						token,
					},
				});
				toast({
					title: t('login.forgot.success'),
					status: 'success',
				});
				navigate('/login');
			} catch (message) {
				toast({
					title: t('common.error'),
					status: 'error',
				});
			}
		},
		[resetPassword, toast, token, t, navigate]
	);

	return <ResetPasswordForm onSubmit={handleResetPassword} errors={{}} />;
}
