import {
	Heading,
	Stack,
	StackDivider,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Navigate,
	useLocation,
	useNavigate,
	useMatches,
} from 'react-router-dom';

import { ForgotPasswordComponent } from '../component/login/ForgotPasswordComponent';
import { ResetPasswordComponent } from '../component/login/ResetPasswordComponent';
import { LoginComponent } from '../component/user/LoginComponent';
import { UserRegistrationForm } from '../component/user/UserRegistrationForm';
import { useAuth } from '../context/AuthContext';

const routes = ['.', 'register', 'reset'];

export function LoginPage() {
	const { t } = useTranslation();

	const { data } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	const matches = useMatches();
	const tabIndex = useMemo(() => {
		if (matches.length <= 0) {
			return 0;
		}
		var match = matches[matches.length - 1];
		switch (match?.id) {
			case 'register':
				return 1;
			case 'forgotPassword':
				return 2;
			case 'resetPassword':
				return 3;
			default:
				return 0;
		}
	}, [matches]);

	var switchTab = useCallback(
		(idx: number) => navigate(routes[idx], { relative: 'route' }),
		[navigate]
	);

	if (data?.user) {
		return <Navigate to={location.state?.from ?? '/'} replace />;
	}

	return (
		<Stack
			direction={{ base: 'column', md: 'row' }}
			h='100vh'
			justifyContent='stretch'
			alignItems={{ base: 'stretch', sm: 'center' }}
			divider={<StackDivider display={{ base: 'none', md: 'unset' }} />}
		>
			<Heading flex={1} mt={5} alignContent='center' textAlign='center'>
				{t('login.title')}
			</Heading>
			<Tabs flex={2} isFitted index={tabIndex} onChange={switchTab}>
				<TabList>
					<Tab>{t('login.login')}</Tab>
					<Tab>{t('login.register')}</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<LoginComponent />
					</TabPanel>
					<TabPanel>
						<UserRegistrationForm onUserRegistered={() => navigate('.')} />
					</TabPanel>
					<TabPanel>
						<ForgotPasswordComponent />
					</TabPanel>
					<TabPanel>
						<ResetPasswordComponent />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Stack>
	);
}
