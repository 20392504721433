import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Button,
	Icon,
	Switch,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import {
	OnChangeFn,
	SortingState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { Athlete, Gender, useAthletListQuery } from '../../gql/gql';

export function AthleteList() {
	const { t } = useTranslation();
	const [sortingState, setSortingState] = useState<SortingState>([]);
	const { data, fetchMore, loading } = useAthletListQuery({
		variables: {
			first: 10,
			order: sortingState.map(({ id, desc }) => ({
				[id]: desc ? 'DESC' : 'ASC',
			})),
		},
	});

	const nav = useNavigate();
	const handleSelect = useCallback(
		(id: Athlete['id']) => nav(`/athletes/${id}`),
		[nav]
	);

	return (
		<>
			<AthleteSelectFromList
				list={data?.athletes?.edges?.map(({ node }) => node)}
				sortingState={sortingState}
				onSortingChange={setSortingState}
				onSelect={handleSelect}
			/>
			{data?.athletes?.pageInfo.hasNextPage && (
				<Button
					leftIcon={<ChevronDownIcon />}
					isLoading={loading}
					onClick={() =>
						fetchMore({
							variables: { after: data.athletes?.pageInfo.endCursor },
						})
					}
				>
					{t('common.more')}
				</Button>
			)}
		</>
	);
}

interface AthleteSelectProps {
	list?: Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'year' | 'gender'>[];
	sortingState?: SortingState | undefined;
	onSortingChange?: OnChangeFn<SortingState>;
	onSelect: (id: Athlete['id']) => void;
}
const columnHelper =
	createColumnHelper<NonNullable<AthleteSelectProps['list']>[0]>();
const defaultColumns = [
	// Accessor Column
	columnHelper.accessor('firstName', {
		cell: (info) => info.getValue(),
		header: () => <Trans i18nKey={'page.athlete.firstName'} />,
		footer: (props) => props.column.id,
	}),
	// Accessor Column
	columnHelper.accessor((row) => row.lastName, {
		id: 'lastName',
		cell: (info) => info.getValue(),
		header: () => <Trans i18nKey={'page.athlete.lastName'} />,
		footer: (props) => props.column.id,
	}),
	// Accessor Column
	columnHelper.accessor('year', {
		header: () => <Trans i18nKey={'page.athlete.year'} />,
		footer: (props) => props.column.id,
	}),
	columnHelper.accessor('gender', {
		header: () => <Trans i18nKey={'page.athlete.gender'} />,
		cell: (v) => (
			<Switch
				variant='gender'
				readOnly
				isChecked={v.getValue() == Gender.Male}
				cursor='default'
			/>
		),
		footer: (props) => props.column.id,
	}),
];
export function AthleteSelectFromList({
	list,
	sortingState,
	onSortingChange,
	onSelect,
}: AthleteSelectProps) {
	const { t } = useTranslation();
	const table = useReactTable({
		data: list ?? [],
		columns: defaultColumns,
		state: {
			sorting: sortingState,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<TableContainer>
			<Table>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Th key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder ? null : onSortingChange ? (
											<Button
												variant='ghost'
												{...{
													className: header.column.getCanSort()
														? 'cursor-pointer select-none'
														: '',
													onClick: header.column.getToggleSortingHandler(),
												}}
											>
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
												{{
													asc: <Icon as={FaSortUp} />,
													desc: <Icon as={FaSortDown} />,
												}[header.column.getIsSorted() as string] ?? (
													<Icon as={FaSort} />
												)}
											</Button>
										) : (
											flexRender(
												header.column.columnDef.header,
												header.getContext()
											)
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => (
						<Tr
							key={row.id}
							cursor='pointer'
							onClick={() => onSelect(row.original.id)}
						>
							{row.getVisibleCells().map((cell) => (
								<Td key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
}
