import { useTranslation } from 'react-i18next';

import { Athlete, useAthleteNameQuery } from '../../gql/gql';

export function AthleteName({ id }: { id: Athlete['id'] }) {
	const { t } = useTranslation();
	const { data } = useAthleteNameQuery({
		variables: { id: id ?? '' },
		fetchPolicy: 'cache-first',
	});

	return (
		<>
			{!data?.athlete
				? '...'
				: t('common.fullName', {
						firstName: data?.athlete?.firstName,
						lastName: data?.athlete?.lastName,
				  })}
		</>
	);
}
