import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Icon,
	VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export function Clubs() {
	const { t } = useTranslation();
	return (
		<VStack>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to='/'>
						<Icon as={FaHome} />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to={'/clubs'}>
						{t('page.clubs.menu', { count: 0 })}
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>
		</VStack>
	);
}
