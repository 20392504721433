import {
	MutableRefObject,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

interface AuthContext {
	data?: {
		user?: {
			id: string;
			eMail: string;
		};
		tokens?: {
			idToken: string;
		};
	};
	actions: {
		setUser: (user: any) => void;
		setTokens: (tokens: any) => void;
	};
}

const authContext = createContext<AuthContext>({
	actions: {
		setUser: () => console.error('uninitialized'),
		setTokens: () => console.error('uninitialized'),
	},
});

export function AuthProvider({
	children,
	authErrorHook,
}: PropsWithChildren & {
	authErrorHook: MutableRefObject<(() => undefined) | undefined>;
}) {
	const [user, setUser] = useState<{ id: string; eMail: string } | undefined>();
	const [tokens, setTokens] = useState<{ idToken: string } | undefined>();
	useEffect(() => {
		try {
			const auth = sessionStorage.getItem('auth');
			const { user, tokens } = JSON.parse(auth ?? '{}');
			setUser(user);
			setTokens(tokens);
		} catch (e) {
			// TODO
			console.error(e);
		}
	}, []);
	authErrorHook.current = () => {
		setUser(undefined);
		setTokens(undefined);
	};
	const context = useMemo(
		() => ({
			data: {
				user,
				tokens,
			},
			actions: {
				setUser: (user: { id: string; eMail: string }) => {
					sessionStorage.setItem(
						'auth',
						JSON.stringify({
							...JSON.parse(sessionStorage.getItem('auth') ?? '{}'),
							user,
						})
					);
					setUser(user);
				},
				setTokens: (tokens: { idToken: string }) => {
					sessionStorage.setItem(
						'auth',
						JSON.stringify({
							...JSON.parse(sessionStorage.getItem('auth') ?? '{}'),
							tokens,
						})
					);
					setTokens(tokens);
				},
			},
		}),
		[user, tokens]
	);

	return (
		<authContext.Provider value={context}>{children}</authContext.Provider>
	);
}
export const AuthConsumer = authContext.Consumer;
export const useAuth = () => useContext(authContext);
