import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Text,
	useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string, type InferType } from 'yup';

import { BasicForm, BasicFormProps } from '../common/BasicForm';
import { PasswordInput } from '../common/PasswordInput';

const schema = object()
	.shape({
		password: string()
			.min(6, 'Die Länge des Passworts muss mindestens 6 Zeichen betragen.')
			.required('Bitte gib ein Passwort ein.'),
	})
	.required();
export type ResetPasswordFormValues = InferType<typeof schema>;

export interface ResetPasswordFormProps
	extends Omit<BasicFormProps, 'onSubmit'> {
	onSubmit: SubmitHandler<ResetPasswordFormValues>;
	errors: FieldErrors<ResetPasswordFormValues>;
}
export function ResetPasswordForm({
	onSubmit,
	errors,
}: ResetPasswordFormProps) {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, isValidating, isValid, errors: formErrors },
	} = useForm({
		mode: 'onChange',
		delayError: 500,
		resolver: yupResolver(schema),
	});

	const handleResetPassword = useMemo(
		() => handleSubmit(onSubmit),
		[handleSubmit, onSubmit]
	);
	return (
		<BasicForm onSubmit={handleResetPassword}>
			<FormControl isInvalid={!!errors.password}>
				<FormLabel>{t('login.reset.password')}</FormLabel>
				<PasswordInput
					placeholder={t('login.reset.password')}
					{...register('password')}
				/>
				<FormErrorMessage>{errors.password?.message}</FormErrorMessage>
			</FormControl>
			<Button
				type='submit'
				formNoValidate
				isDisabled={!isValid}
				isLoading={isSubmitting || isValidating}
			>
				{t('login.reset.submit')}
			</Button>
		</BasicForm>
	);
}
