import { Tag } from '@chakra-ui/react';
import { render } from '@testing-library/react';
import {
	Cell,
	Label,
	LabelList,
	LabelProps,
	Pie,
	PieChart,
	PieLabel,
} from 'recharts';
import {
	CartesianViewBox,
	PolarViewBox,
	ViewBox,
} from 'recharts/types/util/types';

import { Gender } from '../../gql/gql';

interface GenderStatPieProps {
	data: Array<{ key: Gender; value: number }>;
}
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	key,
	value,
	...rest
}: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	console.log(rest);
	return (
		<text
			x={x}
			y={y}
			fill={
				{
					[Gender.Female]: 'var(--chakra-colors-red-800)',
					[Gender.Male]: 'var(--chakra-colors-blue-800)',
				}[key as Gender]
			}
			textAnchor='middle'
			dominantBaseline='central'
		>
			{value}
		</text>
	);
};

export function GenderStatPie({ data, ...rest }: GenderStatPieProps) {
	return (
		<PieChart {...rest}>
			<Pie
				data={data}
				dataKey='value'
				nameKey='key'
				cy='50%'
				cx='50%'
				innerRadius={10}
				label={renderCustomizedLabel}
			>
				{data?.map(({ key }) => (
					<Cell
						key={key}
						fill={
							{
								[Gender.Female]: 'var(--chakra-colors-red-100)',
								[Gender.Male]: 'var(--chakra-colors-blue-100)',
							}[key]
						}
					/>
				))}
				{/* <LabelList
					stroke='var(--chakra-colors-red-800)'
					fill='var(--chakra-colors-red-800)'
				/> */}
				{/* <LabelList position='inside' content={renderLabel} /> */}
			</Pie>
		</PieChart>
	);
}
