import { EditIcon } from '@chakra-ui/icons';
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	HStack,
	IconButton,
} from '@chakra-ui/react';
import { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicForm, BasicFormProps } from './BasicForm';

export interface EditableFormProps extends BasicFormProps {
	isEditable: boolean;
	onSwitchEditable: Dispatch<SetStateAction<boolean>>;
	header?: ReactNode;
	footer?: ReactNode;
}
export function EditableForm({
	isEditable,
	onSwitchEditable,
	children,
	header,
	footer,
	...htmlProps
}: PropsWithChildren<EditableFormProps>) {
	const { t } = useTranslation();

	return (
		<BasicForm {...htmlProps}>
			<Card>
				<CardHeader
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
				>
					{header}
					<IconButton
						hidden={isEditable}
						aria-label='edit'
						icon={<EditIcon />}
						onClick={() => onSwitchEditable(true)}
					/>
				</CardHeader>
				<CardBody>{children}</CardBody>
				<CardFooter hidden={!isEditable} justifyContent={'right'}>
					<HStack>
						<Button type='submit' value='submit'>
							{t('common.save')}
						</Button>
						<Button onClick={() => onSwitchEditable(false)}>
							{t('common.cancel')}
						</Button>
						{footer}
					</HStack>
				</CardFooter>
			</Card>
		</BasicForm>
	);
}
