import {
	Button,
	FormControl,
	FormErrorMessage,
	Icon,
	Input,
	Link,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSignInAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { object, string, type InferType } from 'yup';

import { BasicForm, BasicFormProps } from '../common/BasicForm';
import { PasswordInput } from '../common/PasswordInput';

const schema = object().shape({
	username: string()
		.email('Bitte gib eine eMail-Adresse ein.')
		.required('Bitte gib eine eMail-Adresse ein.'),
	password: string().required('Bitte gib ein Passwort ein.'),
});

export type InputFormValues = InferType<typeof schema>;
export interface LoginFormProps extends Omit<BasicFormProps, 'onSubmit'> {
	onSubmit: SubmitHandler<InputFormValues>;
	errors: FieldErrors<InputFormValues>;
}
export function LoginForm({ errors, onSubmit, ...props }: LoginFormProps) {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, isValidating, isValid, errors: formErrors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'onChange',
		delayError: 500,
	});

	const handleLogin = useMemo(
		() => handleSubmit(onSubmit),
		[handleSubmit, onSubmit]
	);

	return (
		<BasicForm {...props} onSubmit={handleLogin}>
			<FormControl isInvalid={!!formErrors.username}>
				<Input
					type='email'
					placeholder={t('login.username')}
					{...register('username')}
				/>
				<FormErrorMessage>{formErrors.username?.message}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={!!formErrors.password}>
				<PasswordInput
					placeholder={t('login.password')}
					{...register('password')}
				/>
				<FormErrorMessage>{formErrors.password?.message}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={!!errors.root}>
				<FormErrorMessage>
					{t(
						errors.root?.graphql.type === 'not-authorized'
							? 'login.error'
							: ('common.error' as any)
					)}
				</FormErrorMessage>
			</FormControl>
			<Button
				type='submit'
				rightIcon={<Icon as={FaSignInAlt} />}
				isDisabled={!isValid}
				isLoading={isSubmitting && isValidating}
			>
				{t('login.button')}
			</Button>
			<Link as={RouterLink} to='reset'>
				{t('login.forgot.link')}
			</Link>
		</BasicForm>
	);
}
