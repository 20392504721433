import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useCheckboxGroup,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Athlete,
	AthleteFilterInput,
	Gender,
	useAthletListQuery,
} from '../../gql/gql';
import { AthleteSelectFromList } from '../athletes/AthleteList';
import { CreateAthlete } from '../athletes/CreateAthlete';

interface AthleteSelectProps {
	onSelect: (id: Athlete['id']) => void;
	filter: AthleteFilterInput[];
}
export function AthleteSelect({ onSelect, filter }: AthleteSelectProps) {
	const { t } = useTranslation();
	const [yearRange] = useState([1900, 2022]);
	const { value: gender } = useCheckboxGroup({
		defaultValue: [Gender.Male, Gender.Female],
	});

	const { data } = useAthletListQuery({
		variables: {
			first: 10,
			filter: [
				...filter,
				{ gender: { in: gender as Gender[] } },
				{ year: { gte: yearRange[0] } },
				{ year: { lte: yearRange[1] } },
			],
		},
	});

	return (
		<Tabs>
			<TabList defaultValue={data?.athletes?.edges?.length ? 1 : 0}>
				<Tab>{t('athlete.select.new')}</Tab>
				<Tab>{t('athlete.select.fromList')}</Tab>
			</TabList>
			<TabPanels>
				<TabPanel>
					<CreateAthlete onCreated={onSelect} />
				</TabPanel>
				<TabPanel>
					<AthleteSelectFromList
						list={data?.athletes?.edges?.map(({ node }) => node)}
						onSelect={onSelect}
					/>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}
