import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Flex,
	Icon,
	VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { NavLink, Link as RouteLink } from 'react-router-dom';

export function Dashboard() {
	const { t } = useTranslation();
	return (
		<VStack>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to='/' isCurrentPage>
						<Icon as={FaHome} />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbItem isCurrentPage>
					<span>{t('page.dashboard.menu')}</span>
				</BreadcrumbItem>
			</Breadcrumb>
			<Flex justify='stretch' align='stretch' width='100vw' height='100vh'>
				<Button flex={1} m={2} height='5rem' as={RouteLink} to='/contests'>
					{t('page.contests.menu')}
				</Button>
				<Button flex={1} m={2} height='5rem' as={RouteLink} to='/athletes'>
					{t('page.athletes.menu')}
				</Button>
			</Flex>
		</VStack>
	);
}
