import {
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import { forwardRef, Ref, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export interface PasswordInputProps extends Omit<UseFormRegisterReturn, 'ref'> {
	placeholder?: string;
}

export const PasswordInput = forwardRef(function PasswordInput(
	props: PasswordInputProps,
	ref: Ref<any>
) {
	const [showPw, setShowPw] = useState(false);

	return (
		<InputGroup>
			<Input ref={ref} {...props} type={showPw ? 'text' : 'password'} />
			<InputRightElement>
				<IconButton
					size={'sm'}
					aria-label={showPw ? 'hide' : 'show'}
					icon={<Icon as={showPw ? FaEyeSlash : FaEye} />}
					onClick={() => setShowPw(!showPw)}
				/>
			</InputRightElement>
		</InputGroup>
	);
});
