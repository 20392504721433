import { HTMLChakraProps, StackProps, VStack } from '@chakra-ui/react';
import { PropsWithChildren, Ref } from 'react';

export interface BasicFormProps extends StackProps {
	ref?: Ref<HTMLFormElement>;
}

export function BasicForm({
	children,
	ref,
	...htmlProps
}: PropsWithChildren<BasicFormProps>) {
	return (
		<VStack
			as='form'
			m={5}
			justifyContent='stretch'
			alignItems='stretch'
			noValidate
			ref={ref}
			{...htmlProps}
		>
			{children}
		</VStack>
	);
}
