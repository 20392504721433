import { AddIcon, CheckIcon, MinusIcon } from '@chakra-ui/icons';
import {
	Button,
	FormControl,
	FormHelperText,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useToast,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../context/AuthContext';
import {
	Athlete,
	Contest,
	User,
	useAthleteRegistrationsLazyQuery,
	useRegisterAthleteMutation,
	useUnregisterAthleteMutation,
	useUserNameQuery,
} from '../../gql/gql';

interface RegisterAthleteButtonProps {
	athleteId: Athlete['id'];
	contestId: Contest['id'];
	registrar?: User['id'];
}
export function RegisterAthleteButton({
	athleteId,
	contestId,
	registrar,
}: RegisterAthleteButtonProps) {
	const { t } = useTranslation();
	const toast = useToast();
	const { data: registrarUser } = useUserNameQuery({
		variables: { id: registrar!! },
		skip: !registrar,
	});
	const { data: userData } = useAuth();
	const [register, status] = useRegisterAthleteMutation();
	const [unRegister, unRegStatus] = useUnregisterAthleteMutation();
	const [refetchRegistrations] = useAthleteRegistrationsLazyQuery({
		variables: { id: athleteId },
	});

	const handleRegister = useCallback(async () => {
		await register({ variables: { athleteId, contestId } });
		toast({
			title: t('register.athlete.success'),
			status: 'success',
		});
		await refetchRegistrations({ variables: { id: athleteId } });
	}, [register, athleteId, contestId, refetchRegistrations, toast, t]);
	const handleUnRegister = useCallback(async () => {
		await unRegister({ variables: { athleteId, contestId } });
		toast({
			title: t('register.athlete.unregisterSuccess'),
			status: 'success',
		});
		await refetchRegistrations({ variables: { id: athleteId } });
	}, [unRegister, athleteId, contestId, refetchRegistrations, toast, t]);

	if (registrar == null) {
		return (
			<FormControl>
				<Button
					m={3}
					leftIcon={<AddIcon />}
					isLoading={status.loading}
					colorScheme='green'
					onClick={handleRegister}
				>
					{t('register.athlete.submit')}
				</Button>
			</FormControl>
		);
	}

	if (registrar === userData?.user?.id) {
		return (
			<FormControl>
				<Menu>
					<MenuButton
						as={Button}
						m={3}
						leftIcon={<CheckIcon />}
						isLoading={unRegStatus.loading}
					>
						{t('register.athlete.isRegistered')}
					</MenuButton>
					<MenuList>
						<MenuItem icon={<MinusIcon />} onClick={handleUnRegister}>
							{t('register.athlete.unregisterSubmit')}
						</MenuItem>
					</MenuList>
				</Menu>
			</FormControl>
		);
	}

	return (
		<FormControl>
			<Button
				m={3}
				leftIcon={<CheckIcon />}
				isDisabled
				_disabled={{ cursor: 'default' }}
			>
				{t('register.athlete.isRegistered')}
			</Button>
			<FormHelperText>
				{'Gemeldet von: '}
				{registrarUser?.user?.displayName}
			</FormHelperText>
		</FormControl>
	);
}
