import { useParams } from 'react-router-dom';

import { AthleteDeteils } from '../../component/athletes/AthleteDetails';

export function AthleteDetailPage() {
	const { id } = useParams();

	if (id == null) {
		throw new Error('Missing ID');
	}

	return <AthleteDeteils athleteId={id} />;
}
