import { breadcrumbAnatomy, switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(switchAnatomy.keys);

const gender = definePartsStyle({
	thumb: {
		backgroundImage: `data:image/svg+xml;base64,${btoa(
			'<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M128 0c35.346 0 64 28.654 64 64s-28.654 64-64 64c-35.346 0-64-28.654-64-64S92.654 0 128 0m119.283 354.179l-48-192A24 24 0 0 0 176 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H80a24 24 0 0 0-23.283 18.179l-48 192C4.935 369.305 16.383 384 32 384h56v104c0 13.255 10.745 24 24 24h32c13.255 0 24-10.745 24-24V384h56c15.591 0 27.071-14.671 23.283-29.821z"/></svg>'
		)}`,
		bgRepeat: 'no-repeat',
		bgSize: 'contained',
		bgPosition: 'center',
		_checked: {
			backgroundImage: `data:image/svg+xml;base64,${btoa(
				'<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 192 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 0c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64S60.654 0 96 0m48 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H48c-26.51 0-48 21.49-48 48v136c0 13.255 10.745 24 24 24h16v136c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24V352h16c13.255 0 24-10.745 24-24V192c0-26.51-21.49-48-48-48z"/></svg>'
			)}`,
		},
	},
	track: {
		bg: 'red.500',
		_checked: {
			bg: 'blue.500',
		},
	},
});

const breadcrumbHelpers = createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

const breadcrumbStyle = breadcrumbHelpers.defineMultiStyleConfig({
	baseStyle: breadcrumbHelpers.definePartsStyle({
		container: {
			p: 2,
			bg: 'gray.100',
			alignSelf: 'stretch',
		},
	}),
});

const switchTheme = defineMultiStyleConfig({
	sizes: {
		xl: {
			track: {
				width: '10rem',
				height: '1.75rem',
			},
			thumb: {
				width: '5rem',
				height: '1.75rem',
				_checked: {
					transform: 'translateX(5rem)',
				},
			},
		},
	},
	variants: {
		gender,
	},
});

export const theme = extendTheme({
	components: {
		Breadcrumb: breadcrumbStyle,
		Switch: switchTheme,
	},
});
