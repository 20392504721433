import {
	Center,
	List,
	ListItem,
	Table,
	TableContainer,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Athlete, Team } from '../../gql/gql';

interface TeamSelectProps {
	list?: Array<
		Pick<Team, 'id' | 'name'> & {
			athletes: Pick<Athlete, 'id' | 'firstName' | 'lastName'>[];
		}
	>;
	onSelect: (id: Team['id']) => void;
}
export function TeamSelect({ list, onSelect }: TeamSelectProps) {
	const { t } = useTranslation();

	return (
		<TableContainer>
			<Table>
				<Tbody>
					{list?.length ? (
						list.map((team) => (
							<Tr
								key={team.id}
								cursor='pointer'
								onClick={() => onSelect(team.id)}
							>
								<Td>{team.name}</Td>
								<Td>
									<List>
										{team.athletes.map((a) => (
											<ListItem key={a.id}>
												{t('common.fullName', {
													firstName: a.firstName,
													lastName: a.lastName,
												})}
											</ListItem>
										))}
									</List>
								</Td>
							</Tr>
						))
					) : (
						<Tr>
							<Td colSpan={2}>
								<Center>{t('common.empty')}</Center>
							</Td>
						</Tr>
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
}
