import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Icon,
	VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';

import { AthleteDetailPage } from './Detail';
import { AthleteListPage } from './List';

import { AthleteName } from '../../component/athletes/AthleteName';

function AthleteBreadcrumb() {
	const { id } = useParams();

	return (
		<BreadcrumbItem isCurrentPage={true}>
			<span>
				<AthleteName id={id ?? ''} />
			</span>
		</BreadcrumbItem>
	);
}

export function Athletes() {
	const { t } = useTranslation();
	return (
		<VStack alignItems='stretch'>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to='/'>
						<Icon as={FaHome} />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to={'/athletes'}>
						{t('page.athletes.menu')}
					</BreadcrumbLink>
				</BreadcrumbItem>
				<Routes>
					<Route path=':id' Component={AthleteBreadcrumb} />
				</Routes>
			</Breadcrumb>
			<Routes>
				<Route index Component={AthleteListPage} />
				<Route path=':id' Component={AthleteDetailPage} />
			</Routes>
		</VStack>
	);
}
