import { AddIcon } from '@chakra-ui/icons';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	Stack,
	StackDivider,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AthleteList } from '../../component/athletes/AthleteList';
import { CreateAthlete } from '../../component/athletes/CreateAthlete';

export function AthleteListPage() {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Stack
			width='100%'
			direction={{ base: 'column', md: 'row' }}
			justifyContent='stretch'
			alignItems='stretch'
			divider={<StackDivider display={{ base: 'none', md: 'unset' }} />}
		>
			<Card flex={1}>
				<CardHeader>
					<Heading>{t('page.athletes.title')}</Heading>
				</CardHeader>
				<CardBody>
					<Button leftIcon={<AddIcon />} onClick={onOpen}>
						{t('common.new')}
					</Button>
					<Drawer isOpen={isOpen} onClose={onClose} size={'full'}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader>
								<Heading as='h3'>{t('page.athletes.register.heading')}</Heading>
							</DrawerHeader>
							<DrawerBody>
								<CreateAthlete onCreated={onClose} />
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				</CardBody>
			</Card>
			<Card flex={1}>
				<CardBody>
					<AthleteList />
				</CardBody>
			</Card>
		</Stack>
	);
}
