import {
	Button,
	FlexboxProps,
	FormControl,
	FormErrorMessage,
	Icon,
	Input,
	Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSyncAlt } from 'react-icons/fa';
import { object, string } from 'yup';

import { BasicForm, BasicFormProps } from '../common/BasicForm';

import type { InferType } from 'yup';

const schema = object().shape({
	username: string()
		.email('Bitte gib eine eMail-Adresse ein.')
		.required('Bitte gib eine eMail-Adresse ein.'),
});

export type ForgotPasswordFormValues = InferType<typeof schema>;
export interface ForgotPasswordFormProps
	extends Omit<BasicFormProps, 'onSubmit'> {
	onSubmit: SubmitHandler<ForgotPasswordFormValues>;
	errors: FieldErrors<ForgotPasswordFormValues>;
}
export function ForgotPasswordForm({
	errors,
	onSubmit,
	...props
}: ForgotPasswordFormProps & FlexboxProps) {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, isValidating, isValid, errors: formErrors },
	} = useForm({
		mode: 'onChange',
		delayError: 500,
		resolver: yupResolver(schema),
		shouldUseNativeValidation: false,
	});

	const handleReset = useMemo(
		() => handleSubmit(onSubmit),
		[handleSubmit, onSubmit]
	);

	return (
		<BasicForm {...props} onSubmit={handleReset}>
			<Text>{t('login.forgot.text')}</Text>
			<FormControl isInvalid={!!formErrors.username}>
				<Input
					type='email'
					placeholder={t('login.username')}
					{...register('username')}
				/>
				<FormErrorMessage>{formErrors.username?.message}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={!!errors.root}>
				<FormErrorMessage>
					{t(
						errors.root?.graphql.type === 'not-found'
							? 'login.forgot.error'
							: ('common.error' as any)
					)}
				</FormErrorMessage>
			</FormControl>
			<Button
				type='submit'
				rightIcon={<Icon as={FaSyncAlt} />}
				isDisabled={!isValid}
				isLoading={isSubmitting && isValidating}
			>
				{t('login.forgot.submit')}
			</Button>
		</BasicForm>
	);
}
