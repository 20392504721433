import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	ForgotPasswordForm,
	ForgotPasswordFormProps,
} from './ForgotPasswordForm';

import { appolloToFormErrors } from '../../common/error';
import { useResetPasswordMutation } from '../../gql/gql';

export type ForgotPasswordComponentProps = Omit<
	ForgotPasswordFormProps,
	'onSubmit' | 'errors'
>;
export function ForgotPasswordComponent(props: ForgotPasswordComponentProps) {
	const { t } = useTranslation();
	const toast = useToast();
	const [resetPassword, resetPasswordStatus] = useResetPasswordMutation();
	const navigate = useNavigate();

	const errors = useMemo(
		() => appolloToFormErrors(resetPasswordStatus.error),
		[resetPasswordStatus.error]
	);

	const handleResetPassword: ForgotPasswordFormProps['onSubmit'] = useCallback(
		async (formData) => {
			try {
				await resetPassword({
					variables: {
						email: formData.username,
					},
				});
				toast({
					title: t('login.forgot.success'),
					status: 'success',
				});
				navigate('/login');
			} catch (message) {
				toast({
					title: t('common.error' as any),
					status: 'error',
				});
			}
		},
		[resetPassword, toast, t, navigate]
	);

	return (
		<ForgotPasswordForm
			{...props}
			onSubmit={handleResetPassword}
			errors={errors}
		/>
	);
}
