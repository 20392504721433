import {
	Card,
	CardBody,
	CardHeader,
	Heading,
	LinkBox,
	LinkOverlay,
	List,
	ListItem,
	Tag,
	TagLabel,
	TagLeftIcon,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FaFemale, FaMale } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Gender, ListContestsQuery, useEventsQuery } from '../../gql/gql';

const helper =
	createColumnHelper<
		NonNullable<NonNullable<ListContestsQuery['contests']>['edges']>[0]
	>();

const columns = [
	helper.accessor('node.id', {
		id: 'id',
		cell: (info) => info.getValue(),
		header: 'Id',
	}),
	helper.accessor('node.name', {
		id: 'name',
		cell: (info) => info.getValue(),
		header: 'Name',
	}),
	helper.accessor('node.date', {
		id: 'date',
		cell: (info) => info.getValue(),
		header: 'Date',
	}),
];

export default function ContestList() {
	const { t } = useTranslation();
	const { data } = useEventsQuery({});

	return (
		<>
			{data?.events?.edges?.map(({ node }) => (
				<Card key={node.id}>
					<CardHeader>
						<Heading>{node.name}</Heading>
					</CardHeader>
					<CardBody>
						<List>
							{node.contests?.edges?.map(({ node: contest }) => (
								<LinkBox as={ListItem} key={contest.id}>
									<LinkOverlay
										as={Link}
										to={`/contests/${contest.id}`}
										display='flex'
										flexDirection='row'
									>
										<Heading as='h3' size='lg' flex={1}>
											{contest.name}
										</Heading>
										{contest.byGender.map(({ key, value }) => (
											<Tag
												key={key}
												colorScheme={
													{
														[Gender.Female]: 'red',
														[Gender.Male]: 'blue',
													}[key]
												}
											>
												<TagLeftIcon
													as={key === Gender.Male ? FaMale : FaFemale}
												/>
												<TagLabel>{value}</TagLabel>
											</Tag>
										))}
									</LinkOverlay>
									<time>{contest.date}</time>
								</LinkBox>
							))}
						</List>
					</CardBody>
				</Card>
			))}
		</>
	);
}
