import {
	As,
	Icon,
	Switch,
	UseCheckboxProps,
	chakra,
	useCheckbox,
} from '@chakra-ui/react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { Gender } from '../../gql/gql';

export function ToggleButton({ as, ...props }: UseCheckboxProps & { as: As }) {
	const { getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
		useCheckbox(props);

	const inputProps = getInputProps();
	const checxBoxProps = getCheckboxProps();
	const labelProps = getLabelProps();

	return (
		<chakra.label
			display='flex'
			flexDirection={'row'}
			_checked={{ bg: 'teal.600' }}
			cursor='pointer'
			{...htmlProps}
		>
			<input {...inputProps} hidden />
			<Icon as={as} {...labelProps} {...checxBoxProps} />
		</chakra.label>
	);
}

interface GenderToggleProps<V extends FieldValues, K extends string> {
	id?: string;
	name: K;
	control: Control<V>;
	disabled?: boolean;
}
export function GenderToggle<V extends FieldValues, K extends Path<V>>({
	id,
	name,
	control,
	disabled,
}: GenderToggleProps<V, K>) {
	const { field } = useController({ name, control, disabled });

	return (
		<Switch
			id={id}
			variant='gender'
			onChange={(e) => {
				e.preventDefault();
				field.onChange(e.target.checked ? Gender.Male : Gender.Female);
			}}
			isChecked={field.value == Gender.Male}
			isDisabled={field.disabled}
			size='xl'
		/>
	);
}
