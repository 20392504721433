import { ApolloError } from '@apollo/client';
import { FieldErrors, FieldValues } from 'react-hook-form';

export function appolloToFormErrors(
	errors: ApolloError | undefined
): FieldErrors<FieldValues> {
	if (errors == null) {
		return {};
	}

	if (errors.graphQLErrors.length) {
		return {
			root: {
				graphql: {
					type: errors.graphQLErrors.find(Boolean)?.extensions?.code as string,
				},
			},
		};
	}

	return {
		root: {
			['root']: {
				message: errors.message,
				type: errors.name,
			},
		},
	};
}
