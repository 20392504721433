import { ApolloError } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { LoginForm, LoginFormProps } from './LoginForm';

import { useAuth } from '../../context/AuthContext';
import { useLoginMutation } from '../../gql/gql';

function appolloToFormErrors(
	errors: ApolloError | undefined
): LoginFormProps['errors'] {
	if (errors == null) {
		return {};
	}

	if (errors.graphQLErrors.length) {
		return {
			root: {
				graphql: {
					type: errors.graphQLErrors.find(Boolean)?.extensions?.code as string,
				},
			},
		};
	}

	return {
		root: {
			['root']: {
				message: errors.message,
				type: errors.name,
			},
		},
	};
}

export function LoginComponent() {
	const [login, loginStatus] = useLoginMutation();
	const {
		actions: { setUser, setTokens },
	} = useAuth();

	const errors = useMemo(
		() => appolloToFormErrors(loginStatus.error),
		[loginStatus.error]
	);

	const handleLogin: LoginFormProps['onSubmit'] = useCallback(
		async (loginData) => {
			try {
				const { data } = await login({
					variables: {
						email: loginData.username,
						password: loginData.password,
					},
				});

				// setTokens(data?.login.authInfo?.tokens);
				setUser(data?.login.authInfo?.user);
			} catch (message) {
				setTokens(null);
				setUser(null);
			}
		},
		[login, setUser, setTokens]
	);

	return <LoginForm onSubmit={handleLogin} errors={errors} />;
}
