import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Input,
	List,
	ListItem,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { array, object, string, type InferType } from 'yup';

import { Athlete, AthleteFilterInput } from '../../gql/gql';
import i18n from '../../i18n/i18n';
import { AthleteName } from '../athletes/AthleteName';
import { AthleteSelect } from '../athletes/AthleteSelect';
import { BasicForm } from '../common/BasicForm';

const athletesArray = array(object().shape({ athleteId: string().required() }));
const schema = object().shape({
	teamName: string().required(i18n.t('register.team.nameRequired')),
	athletes: athletesArray,
});
export type TeamRegistrationFormValues = InferType<typeof schema>;
export interface TeamRegistrationFormProps {
	min: number;
	max: number;
	onSubmit: SubmitHandler<TeamRegistrationFormValues>;
}
export function TeamRegistrationForm({
	min,
	max,
	onSubmit,
}: TeamRegistrationFormProps) {
	const { t } = useTranslation();

	athletesArray.min(min);
	athletesArray.max(max);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		formState: { isValid, isSubmitting, isValidating },
		control,
		register,
		handleSubmit,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'athletes',
	});

	const handleRegistration = useMemo(
		() => handleSubmit(onSubmit),
		[handleSubmit, onSubmit]
	);
	const handleAthleteSelect = useCallback(
		(id: Athlete['id']) => {
			append({ athleteId: id });
			onClose();
		},
		[append, onClose]
	);

	const filter: AthleteFilterInput[] = [
		{ id: { nin: fields.map(({ athleteId }) => athleteId!) } },
	];

	return (
		<>
			<BasicForm onSubmit={handleRegistration}>
				<FormControl>
					<FormLabel>{t('register.team.nameLbl', { count: min })}</FormLabel>
					<Input {...register('teamName')} />
				</FormControl>
				{fields.length < max && (
					<FormControl>
						<Button rightIcon={<AddIcon />} onClick={onOpen}>
							{t('register.team.addMember')}
						</Button>
					</FormControl>
				)}
				<List>
					{fields.map(({ id, athleteId }, index) => (
						<ListItem key={id}>
							<AthleteName id={athleteId!} />
							<IconButton
								aria-label='Aus Team entfernen'
								icon={<MinusIcon />}
								onClick={() => remove(index)}
							/>
						</ListItem>
					))}
				</List>
				<Button
					type='submit'
					leftIcon={<AddIcon />}
					isDisabled={!isValid || fields.length < min}
					isLoading={isSubmitting || isValidating}
				>
					{t('register.team.submit', { count: min })}
				</Button>
			</BasicForm>

			<Drawer isOpen={isOpen} onClose={onClose} size={'full'}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						<Heading as='h3'>{t('page.contest.registerTeamMember')}</Heading>
					</DrawerHeader>
					<DrawerBody>
						<AthleteSelect onSelect={handleAthleteSelect} filter={filter} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
