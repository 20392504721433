import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { createRef, MutableRefObject } from 'react';

const httpLink = createHttpLink({
	uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token');

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

export let authErrorHook: MutableRefObject<(() => undefined) | undefined> = {
	current: undefined,
};

const logout = onError(({ graphQLErrors, ...params }) => {
	if (graphQLErrors) {
		if (
			graphQLErrors.find((v) => v.extensions?.code === 'AUTH_NOT_AUTHORIZED')
		) {
			localStorage.removeItem('token');
			sessionStorage.removeItem('auth');
			authErrorHook.current?.();
		}
	}
});

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		possibleTypes: {
			Registration: ['AthleteRegistration', 'TeamRegistration'],
		},
		typePolicies: {
			Query: {
				fields: {
					athletes: relayStylePagination(),
					contests: relayStylePagination(),
				},
			},
			Athlete: {
				fields: {
					athleteRegistrations: relayStylePagination(),
				},
			},
			Contest: {
				fields: {
					registrations: relayStylePagination(),
				},
			},
		},
	}),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
	link: authLink.concat(logout).concat(httpLink),
});
