import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deTranslations from './locales/de/translations.json';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			de: {
				translations: deTranslations,
			},
		},
		fallbackLng: 'de',

		returnNull: false,

		defaultNS: 'translations',

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
