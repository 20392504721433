import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Card,
	CardBody,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	StackDivider,
	Table,
	Tbody,
	Td,
	Tr,
	useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { type InferType, number, object, string } from 'yup';

import { RegisterAthleteButton } from './RegisterAthleteButton';

import {
	Athlete,
	Contest,
	Gender,
	useAthleteDetailsQuery,
	useDeleteAthleteMutation,
	useListSingleContestsQuery,
	useUpdateAthleteMutation,
} from '../../gql/gql';
import { EditableForm } from '../common/EditableForm';
import { GenderToggle } from '../common/ToggleButton';

const schema = object()
	.shape({
		firstName: string().required('Bitte gib einen Vornamen ein.'),
		lastName: string().required('Bitte gib einen Nachnamen ein.'),
		year: number().min(1900).max(new Date().getFullYear()).required(),
		gender: string().oneOf([Gender.Female, Gender.Male]).defined().required(),
	})
	.required();
type AthleteType = InferType<typeof schema>;

export function AthleteDeteils({ athleteId }: { athleteId: Athlete['id'] }) {
	const { t } = useTranslation();
	const [isEditable, setIsEditable] = useState(false);
	const nav = useNavigate();
	const toast = useToast();
	const { data, observable } = useAthleteDetailsQuery({
		fetchPolicy: 'cache-first',
		variables: { id: athleteId },
	});

	const [updateAthlete, updateStatus] = useUpdateAthleteMutation();
	const [deleteAthlete, deleteStatus] = useDeleteAthleteMutation();

	const handleDeleteAthlete = useCallback(async () => {
		const { data } = await deleteAthlete({ variables: { athleteId } });
		if (data?.deleteAthlete.boolean) {
			toast({
				title: t('athlete.delete.success'),
				status: 'success',
			});
			nav('/athletes');
		}
	}, [nav, deleteAthlete, toast, athleteId, t]);

	const { data: contests } = useListSingleContestsQuery({
		variables: { first: 10 },
	});
	const values = useMemo(
		() =>
			data?.athlete
				? {
						firstName: data.athlete.firstName,
						lastName: data.athlete.lastName,
						year: data.athlete.year,
						gender: data.athlete.gender,
				  }
				: undefined,
		[data?.athlete]
	);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<AthleteType>({
		mode: 'onChange',
		delayError: 500,
		resolver: yupResolver(schema),
		values: values,
		resetOptions: {
			keepDirtyValues: true,
		},
	});

	const submit = useMemo(
		() =>
			handleSubmit(async ({ firstName, lastName, year, gender }) => {
				const { data } = await updateAthlete({
					variables: {
						id: athleteId,
						firstName: firstName ?? values?.firstName,
						lastName: lastName ?? values?.lastName,
						year: year ?? values?.year,
						gender: gender ?? values?.gender,
					},
				});
				toast({
					title: t('athlete.update.success'),
					status: 'success',
				});
				setIsEditable(false);
			}),
		[handleSubmit, updateAthlete, t, toast, values, athleteId, setIsEditable]
	);

	const registrarId = useCallback(
		(contestId: Contest['id']) => {
			return data?.athlete?.athleteRegistrations?.edges?.find(
				({ node }) => node.contest.id === contestId
			)?.node.registrar.id;
		},
		[data]
	);

	const isRegistered =
		!data?.athlete ||
		data?.athlete?.teams.length ||
		data?.athlete?.athleteRegistrations?.edges?.length;

	const id = useId();
	const genderId = `gender-${id}`;
	const firstNameId = `firstName-${id}`;
	const lastNameId = `lastName-${id}`;
	const yearId = `year-${id}`;

	return (
		<Stack
			width='100%'
			direction={{ base: 'column', md: 'row' }}
			justifyContent='stretch'
			alignItems='stretch'
			divider={<StackDivider display={{ base: 'none', md: 'unset' }} />}
		>
			<EditableForm
				onSubmit={submit}
				isEditable={isEditable}
				onSwitchEditable={setIsEditable}
				header={
					<Heading>
						{t('common.fullName', {
							firstName: data?.athlete?.firstName,
							lastName: data?.athlete?.lastName,
						})}
					</Heading>
				}
				footer={
					<Button
						hidden={!!isRegistered}
						leftIcon={<DeleteIcon />}
						onClick={handleDeleteAthlete}
						isLoading={deleteStatus.loading}
					>
						{t('athlete.delete.apply')}
					</Button>
				}
				flex={1}
			>
				<FormControl>
					<FormLabel htmlFor={firstNameId}>
						{t('athlete.firstNameLbl')}
					</FormLabel>
					<Input
						id={firstNameId}
						{...register('firstName', { disabled: !isEditable })}
					/>
					<FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor={lastNameId}>{t('athlete.lastNameLbl')}</FormLabel>
					<Input
						id={lastNameId}
						{...register('lastName', { disabled: !isEditable })}
					/>
					<FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor={yearId}>{t('athlete.yearLbl')}</FormLabel>
					<Input
						id={yearId}
						{...register('year', { disabled: !isEditable })}
						type='number'
					/>
					<FormErrorMessage>{errors.year?.message}</FormErrorMessage>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor={genderId}>{t('athlete.genderLbl')}</FormLabel>
					<GenderToggle
						id={genderId}
						control={control}
						name='gender'
						disabled={!isEditable}
					/>
					<FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
				</FormControl>
			</EditableForm>
			<Card flex={1}>
				<CardBody>
					<Table>
						<Tbody>
							{contests?.contests?.edges?.map(({ node: contest }) => (
								<Tr key={contest.id}>
									<Td width={'4rem'} align='left'>
										<RegisterAthleteButton
											athleteId={athleteId}
											contestId={contest.id}
											registrar={registrarId(contest.id)}
										/>
									</Td>
									<Td>
										<Box as={'span'} m={3}>
											{contest.name}
										</Box>
									</Td>
								</Tr>
							))}
							{data?.athlete?.teams.map(
								({ id, name, registration: { contest } }) => (
									<Tr key={id}>
										<Td align='left'>
											<Button
												m={3}
												colorScheme='blackAlpha'
												isDisabled
												leftIcon={<CheckIcon />}
												_disabled={{ cursor: 'default' }}
											>
												{t('register.team.isRegistered', { name })}
											</Button>
										</Td>
										<Td>
											<Box as={'span'} m={3}>
												{contest.name}
											</Box>
										</Td>
									</Tr>
								)
							)}
						</Tbody>
					</Table>
				</CardBody>
			</Card>
		</Stack>
	);
}
