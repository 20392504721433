import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	VStack,
	useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaUserPlus } from 'react-icons/fa';
import { object, string } from 'yup';

import { useListClubsQuery, useRegisterUserMutation } from '../../gql/gql';
import { BasicForm } from '../common/BasicForm';
import { PasswordInput } from '../common/PasswordInput';

const schema = object()
	.shape({
		displayName: string().required('Bitte gib einen Namen ein.'),
		email: string()
			.email('Bitte gib eine eMail-Adresse ein.')
			.required('Bitte gib eine eMail-Adresse ein.'),
		password: string()
			.min(6, 'Die Länge des Passworts muss mindestens 6 Zeichen betragen.')
			.required('Bitte gib ein Passwort ein.'),
		club: string().required('Bitte wähle deinen Verein aus.'),
	})
	.required();

export function UserRegistrationForm({ onUserRegistered, ...props }: any) {
	const { t } = useTranslation();
	const toast = useToast();

	const {
		formState: { isSubmitting, isValidating, isValid, errors },
		reset,
		register,
		handleSubmit,
	} = useForm<{
		displayName: string;
		email: string;
		password: string;
		club: string;
	}>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	});
	const [registerUser] = useRegisterUserMutation();

	const { data } = useListClubsQuery();
	const handleRegistration = useMemo(
		() =>
			handleSubmit(async (data) => {
				await registerUser({
					variables: {
						displayName: data.displayName,
						email: data.email,
						password: data.password,
						clubId: data.club,
					},
				});
				toast({
					title: 'Registrierung erfolgreich.',
					status: 'success',
				});
				reset();
				onUserRegistered?.();
			}),
		[registerUser, handleSubmit, toast, reset, onUserRegistered]
	);

	return (
		<BasicForm onSubmit={handleRegistration}>
			<FormControl isInvalid={!!errors.displayName}>
				<FormLabel>{t('register.displayName')}</FormLabel>
				<Input
					placeholder={t('register.displayName') as string}
					{...register('displayName')}
				/>
				<FormErrorMessage>{errors.displayName?.message}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={!!errors.email}>
				<FormLabel>{t('register.email')}</FormLabel>
				<Input
					placeholder={t('register.email') as string}
					{...register('email')}
					type='email'
				/>
				<FormErrorMessage>{errors.email?.message}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={!!errors.password}>
				<FormLabel>{t('register.password')}</FormLabel>
				<PasswordInput
					placeholder={t('register.password') as string}
					{...register('password')}
				/>
				<FormErrorMessage>{errors.password?.message}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={!!errors.club}>
				<FormLabel>{t('register.club')}</FormLabel>
				<Select placeholder={t('register.clubPlh')!} {...register('club')}>
					{data?.clubs?.nodes?.map(({ id, name }) => (
						<option key={id} value={id}>
							{name}
						</option>
					))}
				</Select>
				<FormErrorMessage>{errors.club?.message}</FormErrorMessage>
			</FormControl>
			<Button
				type='submit'
				leftIcon={<Icon as={FaUserPlus} />}
				formNoValidate
				isDisabled={!isValid}
				isLoading={isSubmitting || isValidating}
			>
				{t('register.button')}
			</Button>
		</BasicForm>
	);
}
