import { Box, Button, ButtonGroup, Flex, Icon, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren, useCallback } from 'react';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import {
	Link,
	Navigate,
	Outlet,
	RouterProvider,
	createBrowserRouter,
	useLocation,
} from 'react-router-dom';

import { AuthProvider, useAuth } from './context/AuthContext';
import { useLogoutMutation } from './gql/gql';
import { authErrorHook } from './graphql';
import { Athletes } from './page/Athletes';
import { Clubs } from './page/Clubs';
import { Contests } from './page/Contests';
import { Dashboard } from './page/Dashboard';
import { LoginPage } from './page/LoginPage';
import { ProfilePage } from './page/Profile';

function Layout({ children }: PropsWithChildren) {
	const {
		actions: { setUser, setTokens },
	} = useAuth();
	const [logout] = useLogoutMutation();

	const handleLogout = useCallback(() => {
		logout();
		setUser(null);
		setTokens(null);
	}, [logout, setUser, setTokens]);

	return (
		<VStack alignItems='stretch'>
			<Flex m={2} justify='flex-end'>
				<ButtonGroup isAttached variant='outlined'>
					<Button as={Link} leftIcon={<Icon as={FaUser} />} to='/profile'>
						{t('page.profile.menu')}
					</Button>
					<Button rightIcon={<Icon as={FaSignOutAlt} />} onClick={handleLogout}>
						{t('common.logout')}
					</Button>
				</ButtonGroup>
			</Flex>
			<Box m={2}>{children}</Box>
		</VStack>
	);
}

function ProtectedRoute() {
	const { data } = useAuth();
	const location = useLocation();

	if (!data?.user) {
		return <Navigate to='/login' state={{ from: location.pathname }} replace />;
	}

	return <Outlet />;
}

const router = createBrowserRouter([
	{
		path: '/login/',
		element: <LoginPage />,
		children: [
			{
				id: 'login',
				index: true,
			},
			{
				id: 'register',
				path: 'register',
			},
			{
				id: 'forgotPassword',
				path: 'reset',
			},
			{
				id: 'resetPassword',
				path: 'reset/:token',
			},
		],
	},
	{
		path: '/',
		element: (
			<Layout>
				<ProtectedRoute />
			</Layout>
		),
		children: [
			{
				id: 'dashboard',
				index: true,
				element: <Dashboard />,
			},
			{
				id: 'athletes',
				path: 'athletes/*',
				element: <Athletes />,
			},
			{
				id: 'clubs',
				path: 'clubs/*',
				element: <Clubs />,
			},
			{
				id: 'contests',
				path: 'contests/*',
				element: <Contests />,
			},
			{
				id: 'profile',
				path: 'profile',
				element: <ProfilePage />,
			},
		],
	},
]);

function App() {
	return (
		<AuthProvider authErrorHook={authErrorHook}>
			<RouterProvider router={router} />
		</AuthProvider>
	);
}

export default App;
