import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Switch,
	useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaUserPlus } from 'react-icons/fa';
import { boolean, number, object, string } from 'yup';

import {
	Athlete,
	Gender,
	useAthletListLazyQuery,
	useCreateAthleteMutation,
} from '../../gql/gql';
import { BasicForm } from '../common/BasicForm';

const schema = object()
	.shape({
		firstName: string().required('Bitte gib einen Vornamen ein.'),
		lastName: string().required('Bitte gib einen Nachnamen ein.'),
		year: number().min(1900).max(new Date().getFullYear()).required(),
		isMale: boolean().required(),
	})
	.required();

interface CreateAthleteProps {
	onCreated?: (id: Athlete['id']) => void;
}

export function CreateAthlete({ onCreated }: CreateAthleteProps) {
	const { t } = useTranslation();
	const toast = useToast();
	const {
		formState: { errors, isValid, isSubmitting },
		handleSubmit,
		register,
	} = useForm({
		mode: 'onChange',
		delayError: 500,
		resolver: yupResolver(schema),
	});
	const [updateList] = useAthletListLazyQuery();

	const id = useId();
	const genderId = `gender-${id}`;
	const firstNameId = `firstName-${id}`;
	const lastNameId = `lastName-${id}`;
	const yearId = `year-${id}`;

	const [createAthlete] = useCreateAthleteMutation();

	const submit = useMemo(
		() =>
			handleSubmit(async ({ firstName, lastName, year, isMale }) => {
				const { data } = await createAthlete({
					variables: {
						firstName,
						lastName,
						year,
						gender: isMale ? Gender.Male : Gender.Female,
					},
				});
				toast({
					title: t('athlete.create.success'),
					status: 'success',
				});
				updateList({ variables: { first: 10 } });
				onCreated?.(data?.createAthlete.athlete?.id!);
			}),
		[onCreated, handleSubmit, createAthlete, updateList, t, toast]
	);

	return (
		<BasicForm onSubmit={submit}>
			<FormControl isInvalid={!!errors.firstName}>
				<FormLabel htmlFor={firstNameId}>{t('athlete.firstNameLbl')}</FormLabel>
				<Input
					id={firstNameId}
					placeholder={t('athlete.firstNamePlh')}
					{...register('firstName')}
				/>
				<FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={!!errors.lastName}>
				<FormLabel htmlFor={lastNameId}>{t('athlete.lastNameLbl')}</FormLabel>
				<Input
					id={lastNameId}
					placeholder={t('athlete.lastNamePlh')}
					{...register('lastName')}
				/>
				<FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={!!errors.year}>
				<FormLabel htmlFor={yearId}>{t('athlete.yearLbl')}</FormLabel>
				<Input
					id={yearId}
					placeholder={t('athlete.yearPlh')}
					type='number'
					{...register('year')}
				/>
				<FormErrorMessage>{errors.year?.message}</FormErrorMessage>
			</FormControl>
			<FormControl>
				<FormLabel htmlFor={genderId}>{t('athlete.genderLbl')}</FormLabel>
				<Switch
					id={genderId}
					variant='gender'
					size='xl'
					{...register('isMale')}
				/>
			</FormControl>
			<Button
				m={2}
				isDisabled={!isValid}
				isLoading={isSubmitting}
				leftIcon={<Icon as={FaUserPlus} />}
				type='submit'
			>
				{t('page.athletes.register.submit')}
			</Button>
		</BasicForm>
	);
}
