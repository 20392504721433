import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AgeStates = {
  __typename?: 'AgeStates';
  female: Scalars['Int']['output'];
  male: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Athlete = Node & {
  __typename?: 'Athlete';
  athleteRegistrations?: Maybe<AthleteRegistrationsConnection>;
  club: Club;
  firstName: Scalars['String']['output'];
  gender: Gender;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  registrar: User;
  teams: Array<Team>;
  year: Scalars['Int']['output'];
};


export type AthleteAthleteRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AthleteRegistrationSortInput>>;
  where?: InputMaybe<AthleteRegistrationFilterInput>;
};

export type AthleteFilterInput = {
  and?: InputMaybe<Array<AthleteFilterInput>>;
  athleteRegistrations?: InputMaybe<ListFilterInputTypeOfAthleteRegistrationFilterInput>;
  club?: InputMaybe<ClubFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<GenderOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AthleteFilterInput>>;
  registrar?: InputMaybe<UserFilterInput>;
  teams?: InputMaybe<ListFilterInputTypeOfTeamFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type AthleteRegistration = Registration & {
  __typename?: 'AthleteRegistration';
  athlete: Athlete;
  club: Club;
  contest: Contest;
  id: Scalars['ID']['output'];
  registrar: User;
};

export type AthleteRegistrationFilterInput = {
  and?: InputMaybe<Array<AthleteRegistrationFilterInput>>;
  athlete?: InputMaybe<AthleteFilterInput>;
  club?: InputMaybe<ClubFilterInput>;
  contest?: InputMaybe<ContestFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<AthleteRegistrationFilterInput>>;
  registrar?: InputMaybe<UserFilterInput>;
};

export type AthleteRegistrationSortInput = {
  athlete?: InputMaybe<AthleteSortInput>;
  club?: InputMaybe<ClubSortInput>;
  contest?: InputMaybe<ContestSortInput>;
  id?: InputMaybe<SortEnumType>;
  registrar?: InputMaybe<UserSortInput>;
};

/** A connection to a list of items. */
export type AthleteRegistrationsConnection = {
  __typename?: 'AthleteRegistrationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AthleteRegistrationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AthleteRegistration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AthleteRegistrationsEdge = {
  __typename?: 'AthleteRegistrationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AthleteRegistration;
};

export type AthleteSortInput = {
  club?: InputMaybe<ClubSortInput>;
  firstName?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  registrar?: InputMaybe<UserSortInput>;
  year?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AthletesConnection = {
  __typename?: 'AthletesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AthletesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Athlete>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AthletesEdge = {
  __typename?: 'AthletesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Athlete;
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  user: User;
};

export type Club = Node & {
  __typename?: 'Club';
  athletes: Array<Athlete>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type ClubFilterInput = {
  and?: InputMaybe<Array<ClubFilterInput>>;
  athletes?: InputMaybe<ListFilterInputTypeOfAthleteFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClubFilterInput>>;
  shortName?: InputMaybe<StringOperationFilterInput>;
};

export type ClubSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  shortName?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ClubsConnection = {
  __typename?: 'ClubsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ClubsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ClubsEdge = {
  __typename?: 'ClubsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club;
};

export type Contest = Node & {
  __typename?: 'Contest';
  byAge: Array<AgeStates>;
  byClub: Array<KeyValuePairOfClubAndInt32>;
  byGender: Array<KeyValuePairOfGenderAndInt32>;
  date: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxTeamSize: Scalars['Int']['output'];
  minTeamSize: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parent: Event;
  registrations?: Maybe<RegistrationsConnection>;
  stats: Stats;
};


export type ContestRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<RegistrationSortInput>>;
  where?: InputMaybe<RegistrationFilterInput>;
};

export type ContestFilterInput = {
  and?: InputMaybe<Array<ContestFilterInput>>;
  date?: InputMaybe<DateOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  maxTeamSize?: InputMaybe<IntOperationFilterInput>;
  minTeamSize?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContestFilterInput>>;
  parent?: InputMaybe<EventFilterInput>;
  registrations?: InputMaybe<ListFilterInputTypeOfRegistrationFilterInput>;
};

export type ContestSortInput = {
  date?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  maxTeamSize?: InputMaybe<SortEnumType>;
  minTeamSize?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<EventSortInput>;
};

/** A connection to a list of items. */
export type ContestsConnection = {
  __typename?: 'ContestsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContestsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Contest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContestsEdge = {
  __typename?: 'ContestsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Contest;
};

export type CreateAthleteInput = {
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type CreateAthletePayload = {
  __typename?: 'CreateAthletePayload';
  athlete?: Maybe<Athlete>;
};

export type CreateContestInput = {
  date: Scalars['Date']['input'];
  name: Scalars['String']['input'];
};

export type CreateContestPayload = {
  __typename?: 'CreateContestPayload';
  contest?: Maybe<Contest>;
};

export type CreateTeamInput = {
  athleteIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  team?: Maybe<Team>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DeleteAthleteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAthletePayload = {
  __typename?: 'DeleteAthletePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type Event = Node & {
  __typename?: 'Event';
  contests?: Maybe<ContestsConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type EventContestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ContestSortInput>>;
  where?: InputMaybe<ContestFilterInput>;
};

export type EventFilterInput = {
  and?: InputMaybe<Array<EventFilterInput>>;
  contests?: InputMaybe<ListFilterInputTypeOfContestFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
};

export type EventSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Event>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Event;
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GenderOperationFilterInput = {
  eq?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  neq?: InputMaybe<Gender>;
  nin?: InputMaybe<Array<Gender>>;
};

export type IdOperationFilterInput = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type KeyValuePairOfClubAndInt32 = {
  __typename?: 'KeyValuePairOfClubAndInt32';
  key: Club;
  value: Scalars['Int']['output'];
};

export type KeyValuePairOfGenderAndInt32 = {
  __typename?: 'KeyValuePairOfGenderAndInt32';
  key: Gender;
  value: Scalars['Int']['output'];
};

export type ListFilterInputTypeOfAthleteFilterInput = {
  all?: InputMaybe<AthleteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AthleteFilterInput>;
  some?: InputMaybe<AthleteFilterInput>;
};

export type ListFilterInputTypeOfAthleteRegistrationFilterInput = {
  all?: InputMaybe<AthleteRegistrationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AthleteRegistrationFilterInput>;
  some?: InputMaybe<AthleteRegistrationFilterInput>;
};

export type ListFilterInputTypeOfContestFilterInput = {
  all?: InputMaybe<ContestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContestFilterInput>;
  some?: InputMaybe<ContestFilterInput>;
};

export type ListFilterInputTypeOfRegistrationFilterInput = {
  all?: InputMaybe<RegistrationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RegistrationFilterInput>;
  some?: InputMaybe<RegistrationFilterInput>;
};

export type ListFilterInputTypeOfTeamFilterInput = {
  all?: InputMaybe<TeamFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TeamFilterInput>;
  some?: InputMaybe<TeamFilterInput>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  authInfo?: Maybe<AuthInfo>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAthlete: CreateAthletePayload;
  createContest: CreateContestPayload;
  createTeam: CreateTeamPayload;
  deleteAthlete: DeleteAthletePayload;
  forgotPassword: ForgotPasswordPayload;
  login: LoginPayload;
  logout: LogoutPayload;
  registerAthlete: RegisterAthletePayload;
  registerTeam: RegisterTeamPayload;
  registerUser: RegisterUserPayload;
  unregisterAthlete: UnregisterAthletePayload;
  updateAthlete: UpdateAthletePayload;
  updatePassword: UpdatePasswordPayload;
  updateTeam: UpdateTeamPayload;
  updateUser: UpdateUserPayload;
};


export type MutationCreateAthleteArgs = {
  input: CreateAthleteInput;
};


export type MutationCreateContestArgs = {
  input: CreateContestInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationDeleteAthleteArgs = {
  input: DeleteAthleteInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterAthleteArgs = {
  input: RegisterAthleteInput;
};


export type MutationRegisterTeamArgs = {
  input: RegisterTeamInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationUnregisterAthleteArgs = {
  input: UnregisterAthleteInput;
};


export type MutationUpdateAthleteArgs = {
  input: UpdateAthleteInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  athlete?: Maybe<Athlete>;
  athletes?: Maybe<AthletesConnection>;
  club?: Maybe<Club>;
  clubs?: Maybe<ClubsConnection>;
  contest?: Maybe<Contest>;
  contests?: Maybe<ContestsConnection>;
  event?: Maybe<Event>;
  events?: Maybe<EventsConnection>;
  me: User;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  team?: Maybe<Team>;
  teams?: Maybe<TeamsConnection>;
  user?: Maybe<User>;
};


export type QueryAthleteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAthletesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AthleteSortInput>>;
  where?: InputMaybe<AthleteFilterInput>;
};


export type QueryClubArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClubsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ClubSortInput>>;
  where?: InputMaybe<ClubFilterInput>;
};


export type QueryContestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ContestSortInput>>;
  where?: InputMaybe<ContestFilterInput>;
};


export type QueryEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<TeamSortInput>>;
  where?: InputMaybe<TeamFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type RegisterAthleteInput = {
  athleteId: Scalars['ID']['input'];
  clubId?: InputMaybe<Scalars['ID']['input']>;
  contestId: Scalars['ID']['input'];
};

export type RegisterAthletePayload = {
  __typename?: 'RegisterAthletePayload';
  contest?: Maybe<Contest>;
};

export type RegisterTeamInput = {
  athleteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contestId: Scalars['ID']['input'];
  teamName: Scalars['String']['input'];
};

export type RegisterTeamPayload = {
  __typename?: 'RegisterTeamPayload';
  contest?: Maybe<Contest>;
};

export type RegisterUserInput = {
  clubId: Scalars['ID']['input'];
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<User>;
};

export type Registration = {
  club: Club;
  contest: Contest;
  id: Scalars['ID']['output'];
  registrar: User;
};

export type RegistrationFilterInput = {
  and?: InputMaybe<Array<RegistrationFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  contest?: InputMaybe<ContestFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<RegistrationFilterInput>>;
  registrar?: InputMaybe<UserFilterInput>;
};

export type RegistrationSortInput = {
  club?: InputMaybe<ClubSortInput>;
  contest?: InputMaybe<ContestSortInput>;
  id?: InputMaybe<SortEnumType>;
  registrar?: InputMaybe<UserSortInput>;
};

/** A connection to a list of items. */
export type RegistrationsConnection = {
  __typename?: 'RegistrationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RegistrationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Registration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RegistrationsEdge = {
  __typename?: 'RegistrationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Registration;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stats = {
  __typename?: 'Stats';
  female: Scalars['Int']['output'];
  male: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Team = Node & {
  __typename?: 'Team';
  athletes: Array<Athlete>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  registration: TeamRegistration;
};

export type TeamFilterInput = {
  and?: InputMaybe<Array<TeamFilterInput>>;
  athletes?: InputMaybe<ListFilterInputTypeOfAthleteFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TeamFilterInput>>;
  registration?: InputMaybe<TeamRegistrationFilterInput>;
};

export type TeamRegistration = Registration & {
  __typename?: 'TeamRegistration';
  club: Club;
  contest: Contest;
  id: Scalars['ID']['output'];
  registrar: User;
  team: Team;
};

export type TeamRegistrationFilterInput = {
  and?: InputMaybe<Array<TeamRegistrationFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  contest?: InputMaybe<ContestFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<TeamRegistrationFilterInput>>;
  registrar?: InputMaybe<UserFilterInput>;
  team?: InputMaybe<TeamFilterInput>;
};

export type TeamRegistrationSortInput = {
  club?: InputMaybe<ClubSortInput>;
  contest?: InputMaybe<ContestSortInput>;
  id?: InputMaybe<SortEnumType>;
  registrar?: InputMaybe<UserSortInput>;
  team?: InputMaybe<TeamSortInput>;
};

export type TeamSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  registration?: InputMaybe<TeamRegistrationSortInput>;
};

/** A connection to a list of items. */
export type TeamsConnection = {
  __typename?: 'TeamsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TeamsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TeamsEdge = {
  __typename?: 'TeamsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Team;
};

export type UnregisterAthleteInput = {
  athleteId: Scalars['ID']['input'];
  contestId: Scalars['ID']['input'];
};

export type UnregisterAthletePayload = {
  __typename?: 'UnregisterAthletePayload';
  contest?: Maybe<Contest>;
};

export type UpdateAthleteInput = {
  firstName: Scalars['String']['input'];
  gender: Gender;
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateAthletePayload = {
  __typename?: 'UpdateAthletePayload';
  athlete?: Maybe<Athlete>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  resetToken?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  user?: Maybe<User>;
};

export type UpdateTeamInput = {
  athletes: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  team?: Maybe<Team>;
};

export type UpdateUserInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['UUID']['input'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
};

export type User = Node & {
  __typename?: 'User';
  club: Club;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IdOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  role?: InputMaybe<StringOperationFilterInput>;
};

export type UserSortInput = {
  club?: InputMaybe<ClubSortInput>;
  displayName?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  role?: InputMaybe<SortEnumType>;
};

export type CreateAthleteMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
  gender: Gender;
}>;


export type CreateAthleteMutation = { __typename?: 'Mutation', createAthlete: { __typename?: 'CreateAthletePayload', athlete?: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } | null } };

export type AthleteDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AthleteDetailsQuery = { __typename?: 'Query', athlete?: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender, athleteRegistrations?: { __typename?: 'AthleteRegistrationsConnection', edges?: Array<{ __typename?: 'AthleteRegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, contest: { __typename?: 'Contest', id: string }, registrar: { __typename?: 'User', id: string } } }> | null } | null, teams: Array<{ __typename?: 'Team', id: string, name: string, registration: { __typename?: 'TeamRegistration', id: string, contest: { __typename?: 'Contest', id: string, name: string } } }> } | null };

export type AthleteRegistrationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AthleteRegistrationsQuery = { __typename?: 'Query', athlete?: { __typename?: 'Athlete', id: string, athleteRegistrations?: { __typename?: 'AthleteRegistrationsConnection', edges?: Array<{ __typename?: 'AthleteRegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, contest: { __typename?: 'Contest', id: string }, registrar: { __typename?: 'User', id: string } } }> | null } | null } | null };

export type AthletListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<AthleteSortInput> | AthleteSortInput>;
  filter?: InputMaybe<Array<AthleteFilterInput> | AthleteFilterInput>;
}>;


export type AthletListQuery = { __typename?: 'Query', athletes?: { __typename?: 'AthletesConnection', edges?: Array<{ __typename?: 'AthletesEdge', cursor: string, node: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null };

export type AthleteNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AthleteNameQuery = { __typename?: 'Query', athlete?: { __typename?: 'Athlete', firstName: string, lastName: string } | null };

export type RegisterAthleteMutationVariables = Exact<{
  athleteId: Scalars['ID']['input'];
  contestId: Scalars['ID']['input'];
}>;


export type RegisterAthleteMutation = { __typename?: 'Mutation', registerAthlete: { __typename?: 'RegisterAthletePayload', contest?: { __typename?: 'Contest', id: string, byGender: Array<{ __typename?: 'KeyValuePairOfGenderAndInt32', key: Gender, value: number }>, byClub: Array<{ __typename?: 'KeyValuePairOfClubAndInt32', value: number, key: { __typename?: 'Club', id: string, name: string } }>, registrations?: { __typename?: 'RegistrationsConnection', edges?: Array<{ __typename?: 'RegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, athlete: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } } | { __typename?: 'TeamRegistration', id: string, team: { __typename?: 'Team', id: string, name: string, athletes: Array<{ __typename?: 'Athlete', id: string, firstName: string, lastName: string }> } } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null } | null } };

export type UnregisterAthleteMutationVariables = Exact<{
  athleteId: Scalars['ID']['input'];
  contestId: Scalars['ID']['input'];
}>;


export type UnregisterAthleteMutation = { __typename?: 'Mutation', unregisterAthlete: { __typename?: 'UnregisterAthletePayload', contest?: { __typename?: 'Contest', id: string, byGender: Array<{ __typename?: 'KeyValuePairOfGenderAndInt32', key: Gender, value: number }>, byClub: Array<{ __typename?: 'KeyValuePairOfClubAndInt32', value: number, key: { __typename?: 'Club', id: string, name: string } }>, registrations?: { __typename?: 'RegistrationsConnection', edges?: Array<{ __typename?: 'RegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, athlete: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } } | { __typename?: 'TeamRegistration', id: string, team: { __typename?: 'Team', id: string, name: string, athletes: Array<{ __typename?: 'Athlete', id: string, firstName: string, lastName: string }> } } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null } | null } };

export type DeleteAthleteMutationVariables = Exact<{
  athleteId: Scalars['ID']['input'];
}>;


export type DeleteAthleteMutation = { __typename?: 'Mutation', deleteAthlete: { __typename?: 'DeleteAthletePayload', boolean?: boolean | null } };

export type ListSingleContestsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListSingleContestsQuery = { __typename?: 'Query', contests?: { __typename?: 'ContestsConnection', edges?: Array<{ __typename?: 'ContestsEdge', node: { __typename?: 'Contest', id: string, name: string } }> | null } | null };

export type UpdateAthleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  year: Scalars['Int']['input'];
  gender: Gender;
}>;


export type UpdateAthleteMutation = { __typename?: 'Mutation', updateAthlete: { __typename?: 'UpdateAthletePayload', athlete?: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } | null } };

export type UserNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserNameQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, displayName: string } | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events?: { __typename?: 'EventsConnection', edges?: Array<{ __typename?: 'EventsEdge', cursor: string, node: { __typename?: 'Event', id: string, name: string, contests?: { __typename?: 'ContestsConnection', edges?: Array<{ __typename?: 'ContestsEdge', node: { __typename?: 'Contest', id: string, name: string, date: any, byGender: Array<{ __typename?: 'KeyValuePairOfGenderAndInt32', key: Gender, value: number }> } }> | null } | null } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null };

export type ListContestsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListContestsQuery = { __typename?: 'Query', contests?: { __typename?: 'ContestsConnection', edges?: Array<{ __typename?: 'ContestsEdge', cursor: string, node: { __typename?: 'Contest', id: string, name: string, date: any, minTeamSize: number, maxTeamSize: number } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null };

export type ContestDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ContestDetailsQuery = { __typename?: 'Query', contest?: { __typename?: 'Contest', id: string, name: string, description: string, date: any, minTeamSize: number, maxTeamSize: number, byClub: Array<{ __typename?: 'KeyValuePairOfClubAndInt32', value: number, key: { __typename?: 'Club', id: string, name: string, shortName: string } }>, byGender: Array<{ __typename?: 'KeyValuePairOfGenderAndInt32', key: Gender, value: number }>, byAge: Array<{ __typename?: 'AgeStates', year: number, male: number, female: number }>, registrations?: { __typename?: 'RegistrationsConnection', edges?: Array<{ __typename?: 'RegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, athlete: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } } | { __typename?: 'TeamRegistration', id: string, team: { __typename?: 'Team', id: string, name: string, athletes: Array<{ __typename?: 'Athlete', id: string, firstName: string, lastName: string }> } } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null } | null };

export type MoreContestRegistrationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  cursor: Scalars['String']['input'];
}>;


export type MoreContestRegistrationsQuery = { __typename?: 'Query', contest?: { __typename?: 'Contest', id: string, registrations?: { __typename?: 'RegistrationsConnection', edges?: Array<{ __typename?: 'RegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, athlete: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } } | { __typename?: 'TeamRegistration', id: string, team: { __typename?: 'Team', id: string, name: string, athletes: Array<{ __typename?: 'Athlete', id: string, firstName: string, lastName: string }> } } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null } | null };

export type ContestNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ContestNameQuery = { __typename?: 'Query', contest?: { __typename?: 'Contest', id: string, name: string } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginPayload', authInfo?: { __typename?: 'AuthInfo', user: { __typename?: 'User', id: string, displayName: string } } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutPayload', boolean?: boolean | null } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordPayload', boolean?: boolean | null } };

export type UpdatePasswordWithTokenMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePasswordWithTokenMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UpdatePasswordPayload', user?: { __typename?: 'User', id: string } | null } };

export type ContestTeamRegistrationDetailsQueryVariables = Exact<{
  contestId: Scalars['ID']['input'];
}>;


export type ContestTeamRegistrationDetailsQuery = { __typename?: 'Query', contest?: { __typename?: 'Contest', minTeamSize: number, maxTeamSize: number } | null };

export type FilterAthletesQueryVariables = Exact<{
  contestId: Scalars['ID']['input'];
  gender: Array<Gender> | Gender;
  minYear: Scalars['Int']['input'];
  maxYear: Scalars['Int']['input'];
}>;


export type FilterAthletesQuery = { __typename?: 'Query', athletes?: { __typename?: 'AthletesConnection', edges?: Array<{ __typename?: 'AthletesEdge', cursor: string, node: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null };

export type RegisterTeamMutationVariables = Exact<{
  contestId: Scalars['ID']['input'];
  teamName: Scalars['String']['input'];
  athleteIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type RegisterTeamMutation = { __typename?: 'Mutation', registerTeam: { __typename?: 'RegisterTeamPayload', contest?: { __typename?: 'Contest', id: string, registrations?: { __typename?: 'RegistrationsConnection', edges?: Array<{ __typename?: 'RegistrationsEdge', cursor: string, node: { __typename?: 'AthleteRegistration', id: string, athlete: { __typename?: 'Athlete', id: string, firstName: string, lastName: string, year: number, gender: Gender } } | { __typename?: 'TeamRegistration', id: string, team: { __typename?: 'Team', id: string, name: string, athletes: Array<{ __typename?: 'Athlete', id: string, firstName: string, lastName: string }> } } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null } | null } };

export type ListClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListClubsQuery = { __typename?: 'Query', clubs?: { __typename?: 'ClubsConnection', nodes?: Array<{ __typename?: 'Club', id: string, name: string }> | null } | null };

export type RegisterUserMutationVariables = Exact<{
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  clubId: Scalars['ID']['input'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser: { __typename?: 'RegisterUserPayload', user?: { __typename?: 'User', displayName: string, email: string } | null } };

export type ClubNameQueryVariables = Exact<{
  clubId: Scalars['ID']['input'];
}>;


export type ClubNameQuery = { __typename?: 'Query', club?: { __typename?: 'Club', id: string, name: string, shortName: string } | null };

export type ProfileDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileDataQuery = { __typename?: 'Query', me: { __typename?: 'User', displayName: string, email: string, club: { __typename?: 'Club', id: string, name: string } } };

export const ContestRegistrationsFragmentDoc = gql`
    fragment contestRegistrations on Registration {
  id
  contest {
    id
  }
  registrar {
    id
  }
}
    `;
export const RegistrationsFragmentDoc = gql`
    fragment registrations on RegistrationsConnection {
  edges {
    cursor
    node {
      id
      ... on AthleteRegistration {
        athlete {
          id
          firstName
          lastName
          gender
          year
        }
      }
      ... on TeamRegistration {
        team {
          id
          name
          athletes {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  pageInfo {
    hasNextPage
    endCursor
  }
}
    `;
export const CreateAthleteDocument = gql`
    mutation createAthlete($firstName: String!, $lastName: String!, $year: Int!, $gender: Gender!) {
  createAthlete(
    input: {firstName: $firstName, lastName: $lastName, year: $year, gender: $gender}
  ) {
    athlete {
      id
      firstName
      lastName
      year
      gender
    }
  }
}
    `;
export type CreateAthleteMutationFn = Apollo.MutationFunction<CreateAthleteMutation, CreateAthleteMutationVariables>;

/**
 * __useCreateAthleteMutation__
 *
 * To run a mutation, you first call `useCreateAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthleteMutation, { data, loading, error }] = useCreateAthleteMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      year: // value for 'year'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useCreateAthleteMutation(baseOptions?: Apollo.MutationHookOptions<CreateAthleteMutation, CreateAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAthleteMutation, CreateAthleteMutationVariables>(CreateAthleteDocument, options);
      }
export type CreateAthleteMutationHookResult = ReturnType<typeof useCreateAthleteMutation>;
export type CreateAthleteMutationResult = Apollo.MutationResult<CreateAthleteMutation>;
export type CreateAthleteMutationOptions = Apollo.BaseMutationOptions<CreateAthleteMutation, CreateAthleteMutationVariables>;
export const AthleteDetailsDocument = gql`
    query athleteDetails($id: ID!) {
  athlete(id: $id) {
    id
    firstName
    lastName
    year
    gender
    athleteRegistrations {
      edges {
        cursor
        node {
          ...contestRegistrations
        }
      }
    }
    teams {
      id
      name
      registration {
        id
        contest {
          id
          name
        }
      }
    }
  }
}
    ${ContestRegistrationsFragmentDoc}`;

/**
 * __useAthleteDetailsQuery__
 *
 * To run a query within a React component, call `useAthleteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteDetailsQuery(baseOptions: Apollo.QueryHookOptions<AthleteDetailsQuery, AthleteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteDetailsQuery, AthleteDetailsQueryVariables>(AthleteDetailsDocument, options);
      }
export function useAthleteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteDetailsQuery, AthleteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteDetailsQuery, AthleteDetailsQueryVariables>(AthleteDetailsDocument, options);
        }
export type AthleteDetailsQueryHookResult = ReturnType<typeof useAthleteDetailsQuery>;
export type AthleteDetailsLazyQueryHookResult = ReturnType<typeof useAthleteDetailsLazyQuery>;
export type AthleteDetailsQueryResult = Apollo.QueryResult<AthleteDetailsQuery, AthleteDetailsQueryVariables>;
export const AthleteRegistrationsDocument = gql`
    query athleteRegistrations($id: ID!) {
  athlete(id: $id) {
    id
    athleteRegistrations {
      edges {
        cursor
        node {
          ...contestRegistrations
        }
      }
    }
  }
}
    ${ContestRegistrationsFragmentDoc}`;

/**
 * __useAthleteRegistrationsQuery__
 *
 * To run a query within a React component, call `useAthleteRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteRegistrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<AthleteRegistrationsQuery, AthleteRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteRegistrationsQuery, AthleteRegistrationsQueryVariables>(AthleteRegistrationsDocument, options);
      }
export function useAthleteRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteRegistrationsQuery, AthleteRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteRegistrationsQuery, AthleteRegistrationsQueryVariables>(AthleteRegistrationsDocument, options);
        }
export type AthleteRegistrationsQueryHookResult = ReturnType<typeof useAthleteRegistrationsQuery>;
export type AthleteRegistrationsLazyQueryHookResult = ReturnType<typeof useAthleteRegistrationsLazyQuery>;
export type AthleteRegistrationsQueryResult = Apollo.QueryResult<AthleteRegistrationsQuery, AthleteRegistrationsQueryVariables>;
export const AthletListDocument = gql`
    query athletList($first: Int, $after: String, $order: [AthleteSortInput!], $filter: [AthleteFilterInput!]) {
  athletes(first: $first, after: $after, where: {and: $filter}, order: $order) {
    edges {
      cursor
      node {
        id
        firstName
        lastName
        year
        gender
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useAthletListQuery__
 *
 * To run a query within a React component, call `useAthletListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletListQuery(baseOptions?: Apollo.QueryHookOptions<AthletListQuery, AthletListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthletListQuery, AthletListQueryVariables>(AthletListDocument, options);
      }
export function useAthletListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthletListQuery, AthletListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthletListQuery, AthletListQueryVariables>(AthletListDocument, options);
        }
export type AthletListQueryHookResult = ReturnType<typeof useAthletListQuery>;
export type AthletListLazyQueryHookResult = ReturnType<typeof useAthletListLazyQuery>;
export type AthletListQueryResult = Apollo.QueryResult<AthletListQuery, AthletListQueryVariables>;
export const AthleteNameDocument = gql`
    query athleteName($id: ID!) {
  athlete(id: $id) {
    firstName
    lastName
  }
}
    `;

/**
 * __useAthleteNameQuery__
 *
 * To run a query within a React component, call `useAthleteNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteNameQuery(baseOptions: Apollo.QueryHookOptions<AthleteNameQuery, AthleteNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AthleteNameQuery, AthleteNameQueryVariables>(AthleteNameDocument, options);
      }
export function useAthleteNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AthleteNameQuery, AthleteNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AthleteNameQuery, AthleteNameQueryVariables>(AthleteNameDocument, options);
        }
export type AthleteNameQueryHookResult = ReturnType<typeof useAthleteNameQuery>;
export type AthleteNameLazyQueryHookResult = ReturnType<typeof useAthleteNameLazyQuery>;
export type AthleteNameQueryResult = Apollo.QueryResult<AthleteNameQuery, AthleteNameQueryVariables>;
export const RegisterAthleteDocument = gql`
    mutation registerAthlete($athleteId: ID!, $contestId: ID!) {
  registerAthlete(input: {athleteId: $athleteId, contestId: $contestId}) {
    contest {
      id
      byGender {
        key
        value
      }
      byClub {
        key {
          id
          name
        }
        value
      }
      registrations(first: 10) {
        ...registrations
      }
    }
  }
}
    ${RegistrationsFragmentDoc}`;
export type RegisterAthleteMutationFn = Apollo.MutationFunction<RegisterAthleteMutation, RegisterAthleteMutationVariables>;

/**
 * __useRegisterAthleteMutation__
 *
 * To run a mutation, you first call `useRegisterAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAthleteMutation, { data, loading, error }] = useRegisterAthleteMutation({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useRegisterAthleteMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAthleteMutation, RegisterAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAthleteMutation, RegisterAthleteMutationVariables>(RegisterAthleteDocument, options);
      }
export type RegisterAthleteMutationHookResult = ReturnType<typeof useRegisterAthleteMutation>;
export type RegisterAthleteMutationResult = Apollo.MutationResult<RegisterAthleteMutation>;
export type RegisterAthleteMutationOptions = Apollo.BaseMutationOptions<RegisterAthleteMutation, RegisterAthleteMutationVariables>;
export const UnregisterAthleteDocument = gql`
    mutation unregisterAthlete($athleteId: ID!, $contestId: ID!) {
  unregisterAthlete(input: {athleteId: $athleteId, contestId: $contestId}) {
    contest {
      id
      byGender {
        key
        value
      }
      byClub {
        key {
          id
          name
        }
        value
      }
      registrations(first: 10) {
        ...registrations
      }
    }
  }
}
    ${RegistrationsFragmentDoc}`;
export type UnregisterAthleteMutationFn = Apollo.MutationFunction<UnregisterAthleteMutation, UnregisterAthleteMutationVariables>;

/**
 * __useUnregisterAthleteMutation__
 *
 * To run a mutation, you first call `useUnregisterAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterAthleteMutation, { data, loading, error }] = useUnregisterAthleteMutation({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useUnregisterAthleteMutation(baseOptions?: Apollo.MutationHookOptions<UnregisterAthleteMutation, UnregisterAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnregisterAthleteMutation, UnregisterAthleteMutationVariables>(UnregisterAthleteDocument, options);
      }
export type UnregisterAthleteMutationHookResult = ReturnType<typeof useUnregisterAthleteMutation>;
export type UnregisterAthleteMutationResult = Apollo.MutationResult<UnregisterAthleteMutation>;
export type UnregisterAthleteMutationOptions = Apollo.BaseMutationOptions<UnregisterAthleteMutation, UnregisterAthleteMutationVariables>;
export const DeleteAthleteDocument = gql`
    mutation deleteAthlete($athleteId: ID!) {
  deleteAthlete(input: {id: $athleteId}) {
    boolean
  }
}
    `;
export type DeleteAthleteMutationFn = Apollo.MutationFunction<DeleteAthleteMutation, DeleteAthleteMutationVariables>;

/**
 * __useDeleteAthleteMutation__
 *
 * To run a mutation, you first call `useDeleteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAthleteMutation, { data, loading, error }] = useDeleteAthleteMutation({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useDeleteAthleteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAthleteMutation, DeleteAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAthleteMutation, DeleteAthleteMutationVariables>(DeleteAthleteDocument, options);
      }
export type DeleteAthleteMutationHookResult = ReturnType<typeof useDeleteAthleteMutation>;
export type DeleteAthleteMutationResult = Apollo.MutationResult<DeleteAthleteMutation>;
export type DeleteAthleteMutationOptions = Apollo.BaseMutationOptions<DeleteAthleteMutation, DeleteAthleteMutationVariables>;
export const ListSingleContestsDocument = gql`
    query listSingleContests($first: Int) {
  contests(
    first: $first
    where: {and: [{minTeamSize: {eq: 1}}, {maxTeamSize: {eq: 1}}]}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListSingleContestsQuery__
 *
 * To run a query within a React component, call `useListSingleContestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSingleContestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSingleContestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useListSingleContestsQuery(baseOptions?: Apollo.QueryHookOptions<ListSingleContestsQuery, ListSingleContestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSingleContestsQuery, ListSingleContestsQueryVariables>(ListSingleContestsDocument, options);
      }
export function useListSingleContestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSingleContestsQuery, ListSingleContestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSingleContestsQuery, ListSingleContestsQueryVariables>(ListSingleContestsDocument, options);
        }
export type ListSingleContestsQueryHookResult = ReturnType<typeof useListSingleContestsQuery>;
export type ListSingleContestsLazyQueryHookResult = ReturnType<typeof useListSingleContestsLazyQuery>;
export type ListSingleContestsQueryResult = Apollo.QueryResult<ListSingleContestsQuery, ListSingleContestsQueryVariables>;
export const UpdateAthleteDocument = gql`
    mutation updateAthlete($id: ID!, $firstName: String!, $lastName: String!, $year: Int!, $gender: Gender!) {
  updateAthlete(
    input: {id: $id, firstName: $firstName, lastName: $lastName, year: $year, gender: $gender}
  ) {
    athlete {
      id
      firstName
      lastName
      year
      gender
    }
  }
}
    `;
export type UpdateAthleteMutationFn = Apollo.MutationFunction<UpdateAthleteMutation, UpdateAthleteMutationVariables>;

/**
 * __useUpdateAthleteMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteMutation, { data, loading, error }] = useUpdateAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      year: // value for 'year'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateAthleteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAthleteMutation, UpdateAthleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAthleteMutation, UpdateAthleteMutationVariables>(UpdateAthleteDocument, options);
      }
export type UpdateAthleteMutationHookResult = ReturnType<typeof useUpdateAthleteMutation>;
export type UpdateAthleteMutationResult = Apollo.MutationResult<UpdateAthleteMutation>;
export type UpdateAthleteMutationOptions = Apollo.BaseMutationOptions<UpdateAthleteMutation, UpdateAthleteMutationVariables>;
export const UserNameDocument = gql`
    query userName($id: ID!) {
  user(id: $id) {
    id
    displayName
  }
}
    `;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserNameQuery(baseOptions: Apollo.QueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
      }
export function useUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
        }
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<typeof useUserNameLazyQuery>;
export type UserNameQueryResult = Apollo.QueryResult<UserNameQuery, UserNameQueryVariables>;
export const EventsDocument = gql`
    query events {
  events {
    edges {
      cursor
      node {
        id
        name
        contests {
          edges {
            node {
              id
              name
              date
              byGender {
                key
                value
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ListContestsDocument = gql`
    query listContests($first: Int!, $after: String) {
  contests(first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        name
        date
        minTeamSize
        maxTeamSize
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useListContestsQuery__
 *
 * To run a query within a React component, call `useListContestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListContestsQuery(baseOptions: Apollo.QueryHookOptions<ListContestsQuery, ListContestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListContestsQuery, ListContestsQueryVariables>(ListContestsDocument, options);
      }
export function useListContestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListContestsQuery, ListContestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListContestsQuery, ListContestsQueryVariables>(ListContestsDocument, options);
        }
export type ListContestsQueryHookResult = ReturnType<typeof useListContestsQuery>;
export type ListContestsLazyQueryHookResult = ReturnType<typeof useListContestsLazyQuery>;
export type ListContestsQueryResult = Apollo.QueryResult<ListContestsQuery, ListContestsQueryVariables>;
export const ContestDetailsDocument = gql`
    query contestDetails($id: ID!) {
  contest(id: $id) {
    id
    name
    description
    date
    minTeamSize
    maxTeamSize
    byClub {
      key {
        id
        name
        shortName
      }
      value
    }
    byGender {
      key
      value
    }
    byAge {
      year
      male
      female
    }
    registrations(first: 10) {
      ...registrations
    }
  }
}
    ${RegistrationsFragmentDoc}`;

/**
 * __useContestDetailsQuery__
 *
 * To run a query within a React component, call `useContestDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContestDetailsQuery(baseOptions: Apollo.QueryHookOptions<ContestDetailsQuery, ContestDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContestDetailsQuery, ContestDetailsQueryVariables>(ContestDetailsDocument, options);
      }
export function useContestDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContestDetailsQuery, ContestDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContestDetailsQuery, ContestDetailsQueryVariables>(ContestDetailsDocument, options);
        }
export type ContestDetailsQueryHookResult = ReturnType<typeof useContestDetailsQuery>;
export type ContestDetailsLazyQueryHookResult = ReturnType<typeof useContestDetailsLazyQuery>;
export type ContestDetailsQueryResult = Apollo.QueryResult<ContestDetailsQuery, ContestDetailsQueryVariables>;
export const MoreContestRegistrationsDocument = gql`
    query moreContestRegistrations($id: ID!, $cursor: String!) {
  contest(id: $id) {
    id
    registrations(first: 10, after: $cursor) {
      ...registrations
    }
  }
}
    ${RegistrationsFragmentDoc}`;

/**
 * __useMoreContestRegistrationsQuery__
 *
 * To run a query within a React component, call `useMoreContestRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoreContestRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoreContestRegistrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useMoreContestRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<MoreContestRegistrationsQuery, MoreContestRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MoreContestRegistrationsQuery, MoreContestRegistrationsQueryVariables>(MoreContestRegistrationsDocument, options);
      }
export function useMoreContestRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoreContestRegistrationsQuery, MoreContestRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MoreContestRegistrationsQuery, MoreContestRegistrationsQueryVariables>(MoreContestRegistrationsDocument, options);
        }
export type MoreContestRegistrationsQueryHookResult = ReturnType<typeof useMoreContestRegistrationsQuery>;
export type MoreContestRegistrationsLazyQueryHookResult = ReturnType<typeof useMoreContestRegistrationsLazyQuery>;
export type MoreContestRegistrationsQueryResult = Apollo.QueryResult<MoreContestRegistrationsQuery, MoreContestRegistrationsQueryVariables>;
export const ContestNameDocument = gql`
    query contestName($id: ID!) {
  contest(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useContestNameQuery__
 *
 * To run a query within a React component, call `useContestNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContestNameQuery(baseOptions: Apollo.QueryHookOptions<ContestNameQuery, ContestNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContestNameQuery, ContestNameQueryVariables>(ContestNameDocument, options);
      }
export function useContestNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContestNameQuery, ContestNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContestNameQuery, ContestNameQueryVariables>(ContestNameDocument, options);
        }
export type ContestNameQueryHookResult = ReturnType<typeof useContestNameQuery>;
export type ContestNameLazyQueryHookResult = ReturnType<typeof useContestNameLazyQuery>;
export type ContestNameQueryResult = Apollo.QueryResult<ContestNameQuery, ContestNameQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    authInfo {
      user {
        id
        displayName
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    boolean
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  forgotPassword(input: {email: $email}) {
    boolean
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdatePasswordWithTokenDocument = gql`
    mutation UpdatePasswordWithToken($password: String!, $token: String) {
  updatePassword(input: {newPassword: $password, resetToken: $token}) {
    user {
      id
    }
  }
}
    `;
export type UpdatePasswordWithTokenMutationFn = Apollo.MutationFunction<UpdatePasswordWithTokenMutation, UpdatePasswordWithTokenMutationVariables>;

/**
 * __useUpdatePasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordWithTokenMutation, { data, loading, error }] = useUpdatePasswordWithTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdatePasswordWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordWithTokenMutation, UpdatePasswordWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordWithTokenMutation, UpdatePasswordWithTokenMutationVariables>(UpdatePasswordWithTokenDocument, options);
      }
export type UpdatePasswordWithTokenMutationHookResult = ReturnType<typeof useUpdatePasswordWithTokenMutation>;
export type UpdatePasswordWithTokenMutationResult = Apollo.MutationResult<UpdatePasswordWithTokenMutation>;
export type UpdatePasswordWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordWithTokenMutation, UpdatePasswordWithTokenMutationVariables>;
export const ContestTeamRegistrationDetailsDocument = gql`
    query contestTeamRegistrationDetails($contestId: ID!) {
  contest(id: $contestId) {
    minTeamSize
    maxTeamSize
  }
}
    `;

/**
 * __useContestTeamRegistrationDetailsQuery__
 *
 * To run a query within a React component, call `useContestTeamRegistrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContestTeamRegistrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContestTeamRegistrationDetailsQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useContestTeamRegistrationDetailsQuery(baseOptions: Apollo.QueryHookOptions<ContestTeamRegistrationDetailsQuery, ContestTeamRegistrationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContestTeamRegistrationDetailsQuery, ContestTeamRegistrationDetailsQueryVariables>(ContestTeamRegistrationDetailsDocument, options);
      }
export function useContestTeamRegistrationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContestTeamRegistrationDetailsQuery, ContestTeamRegistrationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContestTeamRegistrationDetailsQuery, ContestTeamRegistrationDetailsQueryVariables>(ContestTeamRegistrationDetailsDocument, options);
        }
export type ContestTeamRegistrationDetailsQueryHookResult = ReturnType<typeof useContestTeamRegistrationDetailsQuery>;
export type ContestTeamRegistrationDetailsLazyQueryHookResult = ReturnType<typeof useContestTeamRegistrationDetailsLazyQuery>;
export type ContestTeamRegistrationDetailsQueryResult = Apollo.QueryResult<ContestTeamRegistrationDetailsQuery, ContestTeamRegistrationDetailsQueryVariables>;
export const FilterAthletesDocument = gql`
    query filterAthletes($contestId: ID!, $gender: [Gender!]!, $minYear: Int!, $maxYear: Int!) {
  athletes(
    first: 10
    where: {and: [{gender: {in: $gender}}, {year: {gte: $minYear}}, {year: {lte: $maxYear}}, {athleteRegistrations: {none: {contest: {id: {eq: $contestId}}}}}]}
  ) {
    edges {
      cursor
      node {
        id
        firstName
        lastName
        year
        gender
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useFilterAthletesQuery__
 *
 * To run a query within a React component, call `useFilterAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterAthletesQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *      gender: // value for 'gender'
 *      minYear: // value for 'minYear'
 *      maxYear: // value for 'maxYear'
 *   },
 * });
 */
export function useFilterAthletesQuery(baseOptions: Apollo.QueryHookOptions<FilterAthletesQuery, FilterAthletesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterAthletesQuery, FilterAthletesQueryVariables>(FilterAthletesDocument, options);
      }
export function useFilterAthletesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterAthletesQuery, FilterAthletesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterAthletesQuery, FilterAthletesQueryVariables>(FilterAthletesDocument, options);
        }
export type FilterAthletesQueryHookResult = ReturnType<typeof useFilterAthletesQuery>;
export type FilterAthletesLazyQueryHookResult = ReturnType<typeof useFilterAthletesLazyQuery>;
export type FilterAthletesQueryResult = Apollo.QueryResult<FilterAthletesQuery, FilterAthletesQueryVariables>;
export const RegisterTeamDocument = gql`
    mutation registerTeam($contestId: ID!, $teamName: String!, $athleteIds: [ID!]) {
  registerTeam(
    input: {contestId: $contestId, teamName: $teamName, athleteIds: $athleteIds}
  ) {
    contest {
      id
      registrations(first: 10) {
        ...registrations
      }
    }
  }
}
    ${RegistrationsFragmentDoc}`;
export type RegisterTeamMutationFn = Apollo.MutationFunction<RegisterTeamMutation, RegisterTeamMutationVariables>;

/**
 * __useRegisterTeamMutation__
 *
 * To run a mutation, you first call `useRegisterTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTeamMutation, { data, loading, error }] = useRegisterTeamMutation({
 *   variables: {
 *      contestId: // value for 'contestId'
 *      teamName: // value for 'teamName'
 *      athleteIds: // value for 'athleteIds'
 *   },
 * });
 */
export function useRegisterTeamMutation(baseOptions?: Apollo.MutationHookOptions<RegisterTeamMutation, RegisterTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterTeamMutation, RegisterTeamMutationVariables>(RegisterTeamDocument, options);
      }
export type RegisterTeamMutationHookResult = ReturnType<typeof useRegisterTeamMutation>;
export type RegisterTeamMutationResult = Apollo.MutationResult<RegisterTeamMutation>;
export type RegisterTeamMutationOptions = Apollo.BaseMutationOptions<RegisterTeamMutation, RegisterTeamMutationVariables>;
export const ListClubsDocument = gql`
    query listClubs {
  clubs {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useListClubsQuery__
 *
 * To run a query within a React component, call `useListClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListClubsQuery(baseOptions?: Apollo.QueryHookOptions<ListClubsQuery, ListClubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClubsQuery, ListClubsQueryVariables>(ListClubsDocument, options);
      }
export function useListClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClubsQuery, ListClubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClubsQuery, ListClubsQueryVariables>(ListClubsDocument, options);
        }
export type ListClubsQueryHookResult = ReturnType<typeof useListClubsQuery>;
export type ListClubsLazyQueryHookResult = ReturnType<typeof useListClubsLazyQuery>;
export type ListClubsQueryResult = Apollo.QueryResult<ListClubsQuery, ListClubsQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($displayName: String!, $email: String!, $password: String!, $clubId: ID!) {
  registerUser(
    input: {displayName: $displayName, email: $email, password: $password, clubId: $clubId}
  ) {
    user {
      displayName
      email
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ClubNameDocument = gql`
    query clubName($clubId: ID!) {
  club(id: $clubId) {
    id
    name
    shortName
  }
}
    `;

/**
 * __useClubNameQuery__
 *
 * To run a query within a React component, call `useClubNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubNameQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useClubNameQuery(baseOptions: Apollo.QueryHookOptions<ClubNameQuery, ClubNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClubNameQuery, ClubNameQueryVariables>(ClubNameDocument, options);
      }
export function useClubNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubNameQuery, ClubNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClubNameQuery, ClubNameQueryVariables>(ClubNameDocument, options);
        }
export type ClubNameQueryHookResult = ReturnType<typeof useClubNameQuery>;
export type ClubNameLazyQueryHookResult = ReturnType<typeof useClubNameLazyQuery>;
export type ClubNameQueryResult = Apollo.QueryResult<ClubNameQuery, ClubNameQueryVariables>;
export const ProfileDataDocument = gql`
    query profileData {
  me {
    displayName
    email
    club {
      id
      name
    }
  }
}
    `;

/**
 * __useProfileDataQuery__
 *
 * To run a query within a React component, call `useProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileDataQuery(baseOptions?: Apollo.QueryHookOptions<ProfileDataQuery, ProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileDataQuery, ProfileDataQueryVariables>(ProfileDataDocument, options);
      }
export function useProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileDataQuery, ProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileDataQuery, ProfileDataQueryVariables>(ProfileDataDocument, options);
        }
export type ProfileDataQueryHookResult = ReturnType<typeof useProfileDataQuery>;
export type ProfileDataLazyQueryHookResult = ReturnType<typeof useProfileDataLazyQuery>;
export type ProfileDataQueryResult = Apollo.QueryResult<ProfileDataQuery, ProfileDataQueryVariables>;