import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	FormControl,
	FormLabel,
	Icon,
	Input,
	VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { object, string, type InferType } from 'yup';

import { EditableForm } from '../../component/common/EditableForm';
import { PasswordInput } from '../../component/common/PasswordInput';
import { useProfileDataQuery } from '../../gql/gql';

const schema = object()
	.shape({
		displayName: string().required('Bitte gib einen Namen ein.'),
		email: string()
			.email('Bitte gib eine eMail-Adresse ein.')
			.required('Bitte gib eine eMail-Adresse ein.'),
		password: string()
			.min(6, 'Die Länge des Passworts muss mindestens 6 Zeichen betragen.')
			.required('Bitte gib ein Passwort ein.'),
		club: string().required('Bitte wähle deinen Verein aus.'),
	})
	.required();
type ProfileType = InferType<typeof schema>;

export function ProfilePage() {
	const { t } = useTranslation();
	const { data, observable } = useProfileDataQuery();

	const [isEditable, setIsEditable] = useState(false);
	const { register, setValue } = useForm<ProfileType>({
		disabled: !isEditable,
		defaultValues: async () => {
			var result = await observable.result();

			return {
				displayName: result.data?.me.displayName,
				email: result.data?.me.email,
				club: result.data?.me.club.name,
				password: '',
			};
		},
	});
	useEffect(() => {
		(async () => {
			if (!isEditable && data != null) {
				setValue('displayName', data.me.displayName);
				setValue('club', data.me.club.name);
				setValue('email', data.me.email);
			}
		})();
	}, [setValue, isEditable, data]);

	return (
		<VStack align='stretch'>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to='/'>
						<Icon as={FaHome} />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbItem>
					<BreadcrumbLink as={NavLink} to={'/profile'} isCurrentPage>
						{t('page.profile.menu')}
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>
			<EditableForm isEditable={isEditable} onSwitchEditable={setIsEditable}>
				<FormControl>
					<FormLabel>{t('page.profile.name')}</FormLabel>
					<Input {...register('displayName')} />
				</FormControl>
				<FormControl>
					<FormLabel>{t('page.profile.email')}</FormLabel>
					<Input {...register('email')} />
				</FormControl>
				<FormControl>
					<FormLabel>{t('page.profile.club')}</FormLabel>
					<Input {...register('club')} />
				</FormControl>
				<FormControl hidden={!isEditable}>
					<FormLabel>{t('page.profile.password')}</FormLabel>
					<PasswordInput {...register('password')} />
				</FormControl>
			</EditableForm>
		</VStack>
	);
}
