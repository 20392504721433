import { AddIcon } from '@chakra-ui/icons';
import {
	AspectRatio,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	List,
	ListIcon,
	ListItem,
	Stack,
	StackDivider,
	Stat,
	StatGroup,
	StatLabel,
	StatNumber,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFemale, FaMale, FaSquare } from 'react-icons/fa';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import {
	PieChart,
	Pie,
	ResponsiveContainer,
	Legend,
	Cell,
	LabelList,
	Tooltip,
	YAxis,
	XAxis,
	BarChart,
	Bar,
} from 'recharts';
import { Props as LegendProps } from 'recharts/types/component/Legend';

import {
	Athlete,
	Club,
	Contest,
	Gender,
	MoreContestRegistrationsDocument,
	Team,
	useClubNameQuery,
	useContestDetailsQuery,
} from '../../gql/gql';
import { AthleteSelectFromList } from '../athletes/AthleteList';
import { AthleteRegistrationComponent } from '../registration/AthleteRegistrationComponent';
import { TeamRegistrationComponent } from '../registration/TeamRegistrationComponent';
import { GenderStatPie } from '../stats/GenderStatPie';
import { TeamSelect } from '../teams/TeamSelect';

function noUndef<T>(a: Array<T | undefined> | undefined) {
	return (a ?? []) as Array<T>;
}
function ClubName({ id }: Pick<Club, 'id'>) {
	const { data } = useClubNameQuery({ variables: { clubId: id } });
	return <>{data?.club?.shortName}</>;
}

const ClubLegend: LegendProps['content'] = ({ payload }) => {
	return (
		<List>
			{payload?.map(({ value, color }) => (
				<ListItem key={value.id}>
					<ListIcon as={FaSquare} color={color} />
					<ClubName id={value.id} />
				</ListItem>
			))}
		</List>
	);
};
const GenderLegend: LegendProps['content'] = ({ payload }) => {
	const { t } = useTranslation();
	return (
		<List display='flex' justifyContent='space-evenly'>
			{payload?.map(({ value, color }) => (
				<ListItem key={value}>
					<ListIcon as={FaSquare} color={color} />
					{t(
						`page.contest.stats.${[Gender.Male, Gender.Female].find(
							(a) => a.toLowerCase() == value.toLowerCase()
						)}` as any
					)}
				</ListItem>
			))}
		</List>
	);
};
const CustomTooltip = ({ active, payload, label }: any) => {
	const { t } = useTranslation();
	if (active && payload && payload.length) {
		return (
			<div className='custom-tooltip'>
				<p className='label'>{`Alter ${2024 - label}`}</p>
				<List display='flex' justifyContent='space-evenly'>
					{payload?.map(({ dataKey, value, color, fill }: any) => (
						<ListItem key={value}>
							<ListIcon
								as={dataKey == 'male' ? FaMale : FaFemale}
								color={color}
								bg={fill}
							/>
							{value}
						</ListItem>
					))}
				</List>
			</div>
		);
	}

	return null;
};

export function ContestDetails({ contestId }: { contestId: Contest['id'] }) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, fetchMore } = useContestDetailsQuery({
		variables: {
			id: contestId,
		},
	});

	const nav = useNavigate();
	const gotoAthleteDetails = useCallback(
		(id: Athlete['id']) => nav(`/athletes/${id}`),
		[nav]
	);
	const gotoTeamDetails = useCallback(
		(id: Team['id']) => /*nav(`/teams/${id}`)*/ {},
		// [nav]
		[]
	);

	const moreRegistrations = useCallback(() => {
		fetchMore({
			query: MoreContestRegistrationsDocument,
			variables: {
				id: contestId,
				cursor: data?.contest?.registrations?.pageInfo.endCursor,
			},
		});
	}, [data?.contest?.registrations?.pageInfo.endCursor, contestId, fetchMore]);

	const isTeamContest =
		data?.contest?.minTeamSize !== 1 || data.contest?.maxTeamSize !== 1;

	const teams = noUndef(
		data?.contest?.registrations?.edges?.map(({ node }) =>
			node.__typename === 'TeamRegistration' ? node.team : undefined
		)
	);
	const athletes = noUndef(
		data?.contest?.registrations?.edges?.map(({ node }) =>
			node.__typename === 'AthleteRegistration' ? node.athlete : undefined
		)
	);

	return (
		<Stack
			width='100%'
			direction={{ base: 'column', md: 'row' }}
			justifyContent='stretch'
			alignItems='stretch'
			divider={<StackDivider display={{ base: 'none', md: 'unset' }} />}
		>
			<Card flex={1}>
				<CardHeader
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
				>
					<Heading>{data?.contest?.name}</Heading>
					<Button m={2} leftIcon={<AddIcon />} onClick={onOpen}>
						{t('contest.register')}
					</Button>
				</CardHeader>
				<CardBody>
					{/* eslint-disable react/no-children-prop */}
					<Markdown
						children={data?.contest?.description ?? ''}
						components={{
							h1({ ...props }) {
								return (
									<Heading as='h2' marginTop='1rem' size='md' {...props} />
								);
							},
							ul({ ...props }) {
								return <UnorderedList {...props} />;
							},
							li({ ...props }) {
								return <ListItem {...props} />;
							},
						}}
					/>
					{/* eslint-enable react/no-children-prop */}
					<Heading
						as='h4'
						hidden={!data?.contest?.registrations?.edges?.length}
					>
						{t('page.contest.stats.heading')}
					</Heading>
					<StatGroup hidden={!data?.contest?.registrations?.edges?.length}>
						{isTeamContest ? (
							<></>
						) : (
							<Stat>
								<StatLabel>{t('page.contest.stats.byGender')}</StatLabel>
								<StatNumber>
									<AspectRatio maxW={300} ratio={1}>
										<ResponsiveContainer width='100%' height='100%'>
											<GenderStatPie data={data?.contest?.byGender ?? []} />
										</ResponsiveContainer>
									</AspectRatio>
								</StatNumber>
							</Stat>
						)}
						<Stat>
							<StatLabel>{t('page.contest.stats.byClub')}</StatLabel>
							<StatNumber>
								<AspectRatio maxW={300} ratio={1}>
									<ResponsiveContainer width='100%' height='100%'>
										<PieChart>
											<Pie
												data={data?.contest?.byClub}
												dataKey='value'
												nameKey='key'
											>
												{data?.contest?.byClub.map(({ key }, index) => (
													<Cell
														key={key.id}
														fill={
															[
																'#28662B',
																'#2A8636',
																'#43AA47',
																'#81C953',
																'#97E589',
															][index % 5]
														}
													/>
												))}
												<LabelList position='inside' />
											</Pie>
											<Legend verticalAlign='bottom' content={ClubLegend} />
										</PieChart>
									</ResponsiveContainer>
								</AspectRatio>
							</StatNumber>
						</Stat>
					</StatGroup>
					<StatGroup hidden={!data?.contest?.registrations?.edges?.length}>
						<Stat>
							<StatLabel>{t('page.contest.stats.byAge')}</StatLabel>
							<StatNumber>
								<AspectRatio maxH={300} ratio={2}>
									<ResponsiveContainer width='100%' height='100%'>
										<BarChart data={data?.contest?.byAge}>
											<XAxis dataKey={'year'} />
											<YAxis allowDecimals={false} />
											<Tooltip content={<CustomTooltip />} />
											<Legend verticalAlign='bottom' content={GenderLegend} />
											<Bar
												dataKey='male'
												color='var(--chakra-colors-blue-800)'
												fill='var(--chakra-colors-blue-100)'
											/>
											<Bar
												dataKey='female'
												color='var(--chakra-colors-red-800)'
												fill='var(--chakra-colors-red-100)'
											/>
										</BarChart>
									</ResponsiveContainer>
								</AspectRatio>
							</StatNumber>
						</Stat>
					</StatGroup>
				</CardBody>
				<CardFooter>
					<Drawer isOpen={isOpen} onClose={onClose} size={'full'}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader>
								<Heading as='h3'>
									{isTeamContest
										? t('page.contest.registerTeam')
										: t('page.contest.registerAthlete')}
								</Heading>
							</DrawerHeader>
							<DrawerBody>
								{isTeamContest ? (
									<TeamRegistrationComponent
										contestId={contestId}
										onRegister={onClose}
									/>
								) : (
									<AthleteRegistrationComponent
										contestId={contestId}
										onRegister={onClose}
									/>
								)}
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				</CardFooter>
			</Card>
			<Card flex={1}>
				<CardHeader>
					<Heading>{t('page.contest.registrations')}</Heading>
				</CardHeader>
				<CardBody>
					{isTeamContest ? (
						<TeamSelect list={teams} onSelect={gotoTeamDetails} />
					) : (
						<AthleteSelectFromList
							list={athletes}
							// sortingState={athleteListSortingState[0]}
							// onSortingChange={athleteListSortingState[1]}
							onSelect={gotoAthleteDetails}
						/>
					)}
					{data?.contest?.registrations?.pageInfo.hasNextPage && (
						<Button onClick={moreRegistrations}>({t('common.more')})</Button>
					)}
				</CardBody>
			</Card>
		</Stack>
	);
}
